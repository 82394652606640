namespace('migoa.components');

migoa.components.CdrSession = Class.create(migoa.Component, {

	infoPath: 'client/user?sis=only',

	initialize : function() {
		var me = this;
	},

	getInfoUser: function () {
		var me = this;

		if (MIGOA.settings.cdrGlobalGesUrl == null || MIGOA.settings.cdrGlobalGesUrl == "") {
			return;
		}
		
		jQuery.ajax({
			type: "post",
			dataType: "json",
			xhrFields: {withCredentials: true},
			url: MIGOA.settings.cdrGlobalGesUrl + me.infoPath,
			data: {'idioma':MIGOA.page.lang, 'portal':'onlyapartments'},
			success: function(response) {
				me.onResponseInfo(response);
			}
		});
	},

	onResponseInfo: function($super, response){
		var me = this;
		if(response.esagente) {
			jQuery('.clave-agente').show();
		} else {
			jQuery('.clave-agente').hide();
		}

		if (response.userinfo) {
		
			// hide login button
			jQuery('li[data-modal="login"]').hide();
		
			let userInfo = jQuery('#drop-user-info');
			if(response.userinfo.photo) {
				jQuery('.user-photo').show();
				jQuery('.user-photo').attr('src',response.userinfo.photo);
			} else {
				jQuery('.user-photo').hide();
				if(jQuery('.user-photo-null').length == 0) {
					jQuery('.user-photo').after('<div class="user-photo-null"></div>');
				}
				jQuery('.user-photo-null').html(response.userinfo.name.substring(0,1));
			}
			userInfo.find('.user-name').html(response.userinfo.name);
			userInfo.find('.user-type').html(response.userinfo.cuenta);
			userInfo.find('.purse-text').html(response.monedero.texto);
			userInfo.find('.purse-value').html(response.monedero.valor);
			userInfo.show();
			
			let dropDown = userInfo.find('.dropdown-menu');
			dropDown.find('li').remove();
			response.menu.forEach(function(element) {
				dropDown.append('<li><a class="icon-'+element.icon+(element.class ? ' '+element.class:'')+'" href="'+element.url+'">&nbsp;&nbsp;&nbsp;'+element.name+'</a></li>');
			});
		}
	}

});