/*
 * migoa generic Page
 * 
 * A Page is... are you sure you don't know what a page is? 
 * 
 */

namespace('migoa');

migoa.Page = Class.create({

	onDocumentLoadedHandlers: [],
	onWindowLoadedHandlers: [],
	windowLoaded: false,
	
	initialize: function() {
		var page=this;

		jQuery.ajaxSettings.traditional = true;
		jQuery.ajaxSetup({
			cache: false,
			jsonp: false
		});
		
		jQuery(document).ready(function() {

			// DocumentLoadedHandlers are run only the first time the page is ready
			for(var i=0;i<page.onDocumentLoadedHandlers.length;i++) {
				page.onDocumentLoadedHandlers[i]();
			}
			page.onDocumentLoadedHandlers = [];
			
			// then run the onDocumentLoaded handler (it can also be invoked after ajax requests)
			page.onDocumentLoaded();
		});
		
		jQuery(window).load(function(){
			// DocumentLoadedHandlers are run only the first time the page is ready
			for(var i=0;i<page.onWindowLoadedHandlers.length;i++) {
				page.onWindowLoadedHandlers[i]();
			}
			page.onWindowLoadedHandlers = [];
			page.windowLoaded = true;
			
		});

		jQuery(window).resize(function() {
			var dimensions = { width: jQuery(window).width(), height: jQuery(window).height() };
		    page.onWindowResized(dimensions);
		});

		jQuery(window).on('pageshow', (event) => {
  			if (event.originalEvent.persisted || page.getNavigationType() === 'back_forward') {
    			page.onPageShow();
  			}
		});
	},
	
	getNavigationType: function() {
		var performance = window.performance;
		if (performance) {
			var entries = performance.getEntriesByType("navigation");
			if (entries && entries.length > 0) {
				return entries[0].type;
			}
		}
		return null;
	},
	
	reload: function() {
		window.location.reload(true);
	},
	
	addOnDocumentLoadedHandler: function(handler){
		if(handler) {
			this.onDocumentLoadedHandlers.push(handler);
		}
	},
	
	addOnWindowLoadedOrFire: function(handler){
		var me = this;
		if(handler) {
			if (me.windowLoaded === true){
				handler();
			} else {
				me.onWindowLoadedHandlers.push(handler);
			}
		}
	},
	
	/* dom:loaded event handler to be overwritten by subclasses */
	onDocumentLoaded: function() {
	},
	
	/* resize event handler to be overwritten by subclasses */
	onWindowResized: function() {
	},

	/* pageShow event handler to be overwritten by subclasses */
	onPageShow: function() {
	},
});
