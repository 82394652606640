namespace('migoa.pages');

migoa.pages.Generic = Class.create(migoa.Page, {

	lang: null,
	languagePrefix: '',
	isBasePathBased: false,
	productSegment: null,
	
	myAds: null,
	historyState: null,
	flash: null,
	userSession: null,
	
	translations: {},

	components: new Object(),
	modals: new Object(),
	dropdowns: new Object(),
	panels: new Object(),
	templates: new Object(),
	
	overlay: null,
	
	currentOpen: null,

	
	/* constructor */
	initialize: function($super,isBasePathBased,productSegment,segmentPrefix) {
		$super();
		var me = this;
		
		me.lang = $('html').attr('lang');
		me.isBasePathBased = (isBasePathBased != undefined) ? isBasePathBased : false;	
		me.languagePrefix = (me.isBasePathBased) ? '/'+me.lang : '';
		me.productSegment = productSegment;

		//ugly trick, but there is no need to carry another prefix and add the logic to use it everywhere
		if (segmentPrefix != null) {
			me.languagePrefix = me.languagePrefix + segmentPrefix;
		}

		me.myAds = new migoa.MyAds();
		me.historyState = new migoa.components.History();
		me.flash = new migoa.components.FlashMessage();
		me.userSession = new migoa.components.CdrSession();
	},
	
	/* Override */
	onDocumentLoaded: function($super) {
		$super();
		var me = this;
		
		// Overlay init
		me.overlay = new migoa.components.Overlay('#page-overlay');
		
		me.initHeaderDropdowns();

		me.initModals();
		
		me.initTooltips();

		// Navigate to buttons
		$('body').on('click.navigateTo', 'button.navigate-to', function(e){
			e.preventDefault();
			e.stopPropagation();
			me.navigateTo($(this));
		});

		jQuery.validator.setDefaults({
			errorClass : 'form-error',
			messages : jQuery.validator.allMessages[jQuery('html').attr('lang')]
		});
		
		jQuery.extend(jQuery.validator.messages, jQuery.validator.allMessages[jQuery('html').attr('lang')]);
		
//		$("#subscribe-btn").click(function() {
//			me.sendSubscribeForm();
//		});		
		
		$(document).on('touchstart', '#modal-full-screen-loader', function(e){
			e.preventDefault();
		});

		$(document).on('click', '#modal-full-screen-loader', function(e){
			e.preventDefault();
		});
		
		$(document).on('click', '#change-lang-link', function(e){
			e.preventDefault();
			var url = $(this).attr('href');
			MIGOA.ui.navigateTo(url);
		});
	
		// load logged-in user info from user-session
		me.userSession.getInfoUser();
	},

	getMyAds: function() {
		return this.myAds;
	},

	showAdsLoader: function(msg) {
		if (msg != null) {
			$("#ads-loader-msg").text(msg);
		}	
		$("#ads-loader").show();
	},

	hideAdsLoader: function() {
		$("#ads-loader").hide();
	},

	navigateTo: function(input) {
		var me = this;

		var url = input.attr('data-href');
		if (url != null) {				
			var target = input.attr('data-target');
			(target == '_blank' && !MIGOA.ui.isMobile()) ? MIGOA.ui.navigateToInNewWindow(url) : MIGOA.ui.navigateTo(url); 
		}

	},
	
	initHeaderDropdowns: function() {
		var me = this;

		me.dropdowns['dropLang'] = new migoa.components.Dropdown('drop-lang');		
		me.dropdowns['dropCurrency'] = new migoa.components.Dropdown('drop-currency');
		me.dropdowns['dropUser'] = new migoa.components.Dropdown('drop-user-info');

	},

	initModals: function(){
		var me = this;
		
		$(document).one('click', '[data-modal=register]', function(){
			if (typeof me.modals['register'] === 'undefined') {
				me.modals['register'] = new migoa.components.modal.SignUp('register', {
					openOnInit: true
				});
			} else {
				me.modals['register'].open();
			}
		});
		
		$(document).one('click', '[data-modal=login]', function() {
			if (typeof me.modals['login'] === 'undefined') {
				me.modals['login'] = new migoa.components.modal.Login('login', {
					openOnInit: true
				});
			} else {
				me.modals['login'].open();
			}
		});
		
		$(document).one('click', '[data-modal=contact]', function() {
			if (typeof me.modals['contact'] === 'undefined') {
				me.modals['contact'] = new migoa.components.modal.Contact('contact', {
					openOnInit: true
				});
			} else {
				me.modals['contact'].open();
			}
		});
	},
	
	getOpenModal: function() {
		var me = this;
		for (var modalName in me.modals) {
			var modal = me.modals[modalName];
			if (modal != null && modal.opened) {
				return modal;
			}
		}
		return null;
	},
	
	initImageCarousel: function($container){
		var me = this;
		if (typeof $container === 'undefined') $container = $(document);				
		
		$('div.swiper-container', $container).each(function(index, element) {
			// Use intersection observer to initialize swiper when the swiper-container is visible on screen 			
			MIGOA.observer.onVisible(element, function(target) { me.buildSwiper(target) });
		});		
	},

	buildSwiper: function(element) {
		var me=this;
		
		// avoid double swiper initialization
		if (element.swiper) {
			return;
		}
		
        var swiper = new Swiper(element, {
        
  	      centeredSlides: true,
  	      preloadImages: false,  // Disable preloading of all images
  	      lazy: {
  	        loadPrevNext: true,
  	        loadPrevNextAmount: 2
  	      },
  	      navigation: {
  	    	  nextEl: '.swiper-button-next',
  	    	  prevEl: '.swiper-button-prev',
  	      },
  	      on: {
  	      	click: function(swp, event) {
  	      		let target = $(event.target);
  	      		if (target.is('img')) {
  	      			let parent = target.closest(".property")
  	      			if (parent) {
  	      				let linkToAd = parent.find('.nu_adlink');
  	      				if (linkToAd && linkToAd.length) {
  	      					linkToAd[0].click();
  	      				}
  	      			}
  	      		}
  	      	}
  	      }
  	    });
	},

	updateCountFavorites: function(add) {
		var $linkFavorites   = jQuery(".link-favorites");
		var spanNumFavorites = $linkFavorites.find("[data-count='items-mylist']");
		var numFavorites     = parseInt(spanNumFavorites.html(), 10);
		if (add) {
			numFavorites++;
		} else {
			numFavorites--;
		}

		if (numFavorites) {
			$linkFavorites.show();
		} else {
			$linkFavorites.hide();
		}

		spanNumFavorites.html(numFavorites);
	},
	
	
	initTooltips: function () {
		var originalLeave = $.fn.tooltip.Constructor.prototype.leave;

		jQuery('[data-toggle=tooltip]').each(function(){
			
			// If tooltip data-auto=false then do not init it with generic method
			if ($(this).data('auto') === 'false') return;
			
			// Ignore elements with tooltips already initialized
			if ($(this).data('original-title') != null) return;
			
			$(this).tooltip({
				placement: 'auto top',
				delay: {
					show: 0,
					hide: 0
				  }					
			});
		});
	},
	
	getTranslation: function(key,placeholders) {
		var me = this;
		var translation = me.translations[key];
		
		if (translation == null) {
			translation = key;
		}
		
		if (placeholders != null) {
			for (var i=0;i<placeholders.length;i++) {
				translation = translation.replace('#',placeholders[i]);
			}
		}
		return translation;
	},
	
	markAdAsUnSaved : function(productId,unsaveWord,unsaveTitle) {
		var me = this;

		var hearts = jQuery('#ad_' + productId+' .icon-love');
		me.updateCountFavorites(false);
		hearts.each(function(){
			var heart = jQuery(this);
			heart.removeClass("icon-on");
			me.toggleFavoriteAction(heart);
		});

		// Flash info message
		me.flash.appendFlash({
			type: 'info',
			icon: 'love',
			text: me.getTranslation('deleted_from_favorites')			
		});		
	},

	markAdAsSaved : function(productId,unsaveWord,unsaveTitle) {
		var me = this;
		
		var hearts = jQuery('#ad_' + productId+' .icon-love');
		me.updateCountFavorites(true);
		hearts.each(function(){
			var heart = jQuery(this);
			heart.addClass("icon-on");
			me.toggleFavoriteAction(heart);
		});

		// Flash info message
		me.flash.appendFlash({
			type: 'info',
			icon: 'love',
			text: me.getTranslation('saved_to_favorites')			
		});
	},
	
	toggleFavoriteAction: function (heart) {
		var me = this;
		var onclick = heart.attr("onclick");
		if (onclick != null) {
			if(onclick.indexOf('unsave') > -1) {
				onclick = onclick.replace('unsave','save');
			} else {
				onclick = onclick.replace('save','unsave');
			}
			heart.attr("onclick",onclick);
		}
	},
	
//	sendSubscribeForm: function() {
//		var me=this;
//		
//		if ($('#subscribe_form').validate().form()) {
//			jQuery.post(me.languagePrefix+'/_ajax/subscribe', {email:$("#email_subscribe").val()},
//					function (response) {
//						if (MIGOA.page.modals['ajax-response'] !== undefined){
//							// Set content
//							MIGOA.page.modals['ajax-response'].setContent('<p>' + response.text + '</p>');
//							MIGOA.page.modals['ajax-response'].open();
//						} else {
//							// Init and set
//							MIGOA.page.modals['ajax-response'] = new migoa.components.modal.AjaxResponse(response);
//						}
//						MIGOA.analytics.trackEvent('SignUp', 'NEWSLETTER', 'post');
//					},
//					'json'
//			);
//		}
//	}
	
});