namespace('migoa.components.dropdown');

migoa.components.dropdown.Ages = Class.create(migoa.components.Dropdown, {

	initialize : function($super, id, callMethod) {
		var me = this;
		$super(id, callMethod);
		$('#accept-children-ages button').on('click', function(event) {
			me.close(event);
			return false;
		});
		
		// load previous value from html input
		var str = $('#childrenAges').val();
		if (str != null && str != '') {
			me.setSelectedValue(str.split(','));
			me.changeNumberValue(me.selectedValue.length, true); // skip open ages dropdown
			me.updateUI();
		}
	},

	open: function($super) {
		$super();
		//MIGOA.page.overlay.overlayShow();
	},

	close: function($super) {
		var me = this;
		if (me.opened) {
			$super();
			
			// update selected value from inputs
 			var ages = [];
 			$('.children-age').each(function(index, element) {
 				ages.push($(element).val());
 			});
 			ages.pop();
 			
			me.setSelectedValue(ages);
			me.updateUI();
			
			//MIGOA.page.overlay.overlayHide();
		}
	},

	changeNumberValue: function (value, skipOpen) {
		var me = this;
		var ages = [];
		$('#inputs-children-ages').empty();
		var childAges = me.selectedValue || [];
		
		for (let index = 0; index < value; index++) {
			if(childAges[index]) {
				ages.push(childAges[index]);
			} else {
				ages.push(0);
			}
			me.addAgeInput(index, (ages[index] ? ages[index] : ''));
		}
		$('#label-ages').css('fontSize', '');
		
		me.setSelectedValue(ages);
		me.updateUI();
		
		if(ages.length > 0 && !skipOpen) {
			setTimeout(function() {me.open();}, 200);
		}
	},

	addAgeInput: function(index, age) {
		let me = this;
		let ageSample = $('#age-sample').clone();
		ageSample.removeAttr('id');
		let label = $(ageSample.children('label').get(0));
		label.attr('for','age_'+index);
		label.html(label.html().replace('#numberChild',index+1));
		let childrenAge = $(ageSample.find('.children-age').get(0));
		childrenAge.attr('id','age_'+index);
		childrenAge.attr('name','age_'+index);
		if(age) {
			$(childrenAge.find('option[value='+age+']').get(0)).attr('selected','selected');
		}
		ageSample.show();
		$('#inputs-children-ages').append(ageSample);

	},

	updateUI: function() {
		var me = this;

		var ages = me.selectedValue;
		if (ages != null && ages.length > 0) {
		
			$('#drop-children-ages').show();
			
			let labelAges = '';
			$.each(ages, function(index, age){
				if(age) {
					labelAges+=age+', ';
				}
			});
			if(labelAges) {
				$('#label-ages').text(labelAges.substr(0, labelAges.length-2));
			} else {
				$('#label-ages').text('--');
			}
			
			$('#container-destination').removeClass('col-xs-4');
			$('#container-destination').addClass('col-xs-3');
			
			$('#container-drop-children').removeClass('col-xs-12');
			$('#container-drop-children').addClass('col-xs-6');

			if ($('#container-drop-adults').length) {
				$('#container-drop-adults').removeClass('col-sm-6');
				$('#container-drop-adults').addClass('col-sm-4');

				$('#container-drop-children').parent().removeClass('col-sm-6');
				$('#container-drop-children').parent().removeClass('col-xs-6');
				$('#container-drop-children').parent().addClass('col-sm-8');
				$('#container-drop-children').parent().addClass('col-xs-8');
			} else {
//				$('#container-children').removeClass('col-xs-1');
//				$('#container-children').addClass('col-xs-2');
			}
		
		} else {
		
			$('#drop-children-ages').hide();
			
			$('#container-destination').removeClass('col-xs-3');
			$('#container-destination').addClass('col-xs-4');
			
			$('#container-drop-children').removeClass('col-xs-6');
			$('#container-drop-children').addClass('col-xs-12');

			if ($('#container-drop-adults').length) {
				$('#container-drop-adults').removeClass('col-sm-4');
				$('#container-drop-adults').addClass('col-sm-6');

				$('#container-drop-children').parent().removeClass('col-sm-8');
				$('#container-drop-children').parent().removeClass('col-xs-8');
				$('#container-drop-children').parent().addClass('col-sm-6');
				$('#container-drop-children').parent().addClass('col-xs-6');
			} else {
//				$('#container-children').removeClass('col-xs-2');
//				$('#container-children').addClass('col-xs-1');
			}
		}
	},
	
	setSelectedValue: function(ages) {
		var me=this;

		if (ages != null && ages.length > 0) {
			me.selectedValue = ages;
		} else {
			me.selectedValue = [];
		}

		if (me.selectedValue.length > 0) {
			$('#childrenAges').val(me.selectedValue.join(','));
		} else {
			$('#childrenAges').val('');
		}
	}
	
});