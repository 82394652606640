namespace('migoa.components');

migoa.components.Spinner = Class.create(migoa.Component, {

	container: null,
	prepend: false,
	append: false,
	size: 68,   // supported sizes: 156, 68, 36, 18
	
	initialize : function(element, options) {
		var me = this;
		
		// if customized options
		if (typeof options !== 'undefined') $.extend(true, me, options);
		
		me.container = element;
				
		var icon = new Image();
		icon.src = MIGOA.settings.cdnUrl+"images/public/loading_" + me.size + "px.png";
		
		var spinnerHtml = $('<div />', {'class': "spinner"}).html(icon);
		
		// Type button
		if (me.container.attr('type') !== undefined) {
			me.container.wrap("<div class='wrapper'></div>").after(spinnerHtml);
		} else {
			if (me.prepend) {
				me.container.prepend(spinnerHtml);
			} else if (me.append) {
				me.container.append(spinnerHtml);
			} else {
				me.container.html(spinnerHtml);
			}
		}
	}

});
