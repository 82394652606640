namespace('migoa.components');

migoa.components.History = Class.create(migoa.Component, {

	backEventActive: false,
	firstLoad: true,
	index: 1,	
	historyArray: null,
	backUrl: null,
	skipAllCallbacks: false,
	backSerp: false,
	
	/* constructor */
	initialize : function() {
		var me = this;
		
		me.historyArray = new Object();
		
		$(window).on('popstate', function (e) {					
			
			if (me.skipAllCallbacks) {
				me.skipAllCallbacks = false;
				return;
			}
			
			var state = e.originalEvent.state;
			// if (state == null) return;
									
			me.index = state == null ? 0 : state.index;
			
			// var action = me.historyArray[me.index];
			var action = state;
			
			// v2
			if (action && action.state) {
				try {
					eval(action.state);
				} catch(e) {
					window.location.reload();
				}
			} else {
				
				if (typeof action !== 'undefined' && action != null && typeof action.callback === 'undefined') {
					
					action.callback = 'document.location.reload';
					
				} else if (action == null) {				
					
					if (state && state.callback){
						var action = {};
						action.callback = me.invertMethod(state.callback);					
					} else {
						window.location.reload();
						return false;
					}				
					
				}
				
				me.backEventActive = true;					
				
				if (action.callback) {
					action = action.callback.split('.');
					
					if (action[0] === 'modals' || action[0] === 'panels') {
						if (MIGOA.page[action[0]] && MIGOA.page[action[0]][action[1]] && MIGOA.page[action[0]][action[1]][action[2]]) {
							MIGOA.page[action[0]][action[1]][action[2]]();
						}
					} else if (action[0] === 'document') {
						if (window[action[0]] && window[action[0]][action[1]] && window[action[0]][action[1]][action[2]]) {
							window[action[0]][action[1]][action[2]]();
						}
					}
				}
				
				me.backEventActive = false;
			}
		});
		
		
		/** Modals **/
		$(window).on('md.open', function(e, modal){
			
			me.backUrl = window.location.origin + window.location.pathname + window.location.search;		
			
			if (!me.backEventActive) {

				me.replaceState({
					url: me.backUrl,
					callback: 'modals.' + modal.id + '.close'
				});
				
				me.pushState({
					url: window.location.origin + window.location.pathname + window.location.search + '#md-' + modal.id,
				});
			}					
		});
		
		$(window).on('md.close', function(e, modal){
			
			if (!me.backEventActive) me.back(-1, true);

		});
		
		/** Panels **/
		$(window).on('pnl.beforeOpen', function(e, panel){
			
			me.backUrl = window.location.origin + window.location.pathname + window.location.search;
			
			if (!me.backEventActive) {
				
				me.replaceState({
					url: me.backUrl,
					callback: 'panels.' + panel.name + '.close'
				});				
				
				me.pushState({
					url: window.location.origin + window.location.pathname + window.location.search + '#pn-' + panel.id,
				});
			}
		});
		
		$(window).on('pnl.beforeClose', function(e, panel){
			
			if (!me.backEventActive) me.back(-1, true);

		});			
	},
	
	pushState: function(state){
		var me = this;
		
		me.firstLoad = false;
		
		if (typeof state === 'undefined') return;
		
		state.index = me.index;
				
		me.historyArray[me.index] = state;
		
		window.history.pushState(state, '', state.url);
		
		me.index++;
		
	},
	
	replaceState: function(state) {
		var me = this;
		
		if (me.backEventActive) return;
		
		if (!state) return;
		
		if (typeof increment === 'undefined') increment = false;
		
		me.index--;
		
		state.index = me.index;
		
		me.historyArray[me.index] = state;
		
		window.history.replaceState(state, '', state.url);
		
		me.index++;
		
	},
	
	back: function(num, skipAllCallbacks) {
		var me = this;
		
		me.skipCallback = true;
		
		me.skipAllCallbacks = skipAllCallbacks || false;
		
		history.go(num);
		
	},
	
	invertMethod: function(callback){
		var me = this;
		
		callback = callback.split('.');
		
		if (callback.length === 3) {
			
			if (callback[0] === 'modals' || callback[0] === 'panels') {
				
				if (callback[2] === 'open') callback[2] = 'close';
				if (callback[2] === 'close') callback[2] = 'open';
				
				return callback.join('.');
			}
		}
		
		return false;
	}

});