/*
 * Global Singleton for migoa client code
 * 
 * MIGOA is a global object that provides helper functions
 * common to all pages and JavaScript classes.
 *  
 */

var MIGOA = {

		version:       '1.0',
		
		ui:            new migoa.UI(),               // user interface helper
		
		analytics:     new migoa.google.Analytics(), // Google Analytics integration
		
		storage:       new migoa.Storage(true),      // Storage helper (cookie fallback enabled)
		
		page:          null,                         // current page object

		settings:	   new Object(),
		
		observer:	   new migoa.Observer(),		 // IntersectionObserver wrapper
		
		history:	   window.History.createBrowserHistory({ forceRefresh: false })
};