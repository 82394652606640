/*
 * migoa Intersection Observer wrapper
 * 
 */

namespace('migoa');

migoa.Observer = Class.create({

	supported: false,
	
	initialize: function() {
	
		var me=this;
		
		// Check if IntersectionObserver API is natively supported...
		if ('IntersectionObserver' in window &&
		    'IntersectionObserverEntry' in window &&
		    'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
			me.supported=true;
		}
	},
	
	
	onVisible: function(element, callback) {
		var me=this;
		
		if (element._isObserved) {
			return;	// avoid observing the same element multiple times..
		}
		element._isObserved = true;
		
		if (me.supported) {
			// create a new intersection observer that will call the callback once the element is intersecting the screen		
			var observer = new IntersectionObserver(function(entries) {
				if (entries[0].intersectionRatio > 0) {
					callback && callback(entries[0].target);
				}
			}, { threshold: [0] });
			observer.observe(element);
			
		} else {
			// fallback to directly invoke callbacks on legacy browsers
			callback && callback(element);
		}
	}
	
});