namespace('migoa.components');

migoa.components.Modals = Class.create(migoa.Component, {

	id : null,
	opened : false,
	animating : false,
	targt : null,
	closeOthers : true,
	persistance : 0,
	submitButton: null,
	submitText: '',
	afterLoad: function(){},
	openOnInit: false,
	autoClose: null,
	_autoCloseTimmer: null,
	full: true,
	overlay: true,
	position: null,
	blockScrollOnPage: true,
	addToHistory: true,
	contentId: null,


	/* constructor */
	initialize : function(modal_id, options) {
		var me = this;

		// Merge specific options overrides defaults
		$.extend(true, me, options);	
		
		// if modal has contentId, join to id, manage multiple modals with diferent contents
		if (me.contentId){
			me.id = modal_id + "_" + me.contentId;
		} else {
			me.id = modal_id;
		}
		

		// modal exists?
		if($('#modal-' + me.id).length > 0){
			
			me.targt = $('#modal-' + me.id);
			
			if (me.openOnInit) me.open();
			
			me.onModalLoaded();
			
			// Attach modal launchers
			me.addListeners();			
			
		}else{
			me.loadFromServer(modal_id);
		}

	},
	
	loadFromServer: function(modal_id){
		var me = this;
		
		$.get(MIGOA.page.languagePrefix+"/_ajax/get_modal", {id: modal_id, contentId: me.contentId})
		.done(function(data){
			me.targt = $(data).filter('.md-modal');

			if(jQuery('#modals').length == 0) {
				jQuery('body').append("<div id='modals'/>");
			}
			me.targt.appendTo('#modals');
			
			me.onModalLoaded();

			// Open id set
			if (me.openOnInit) {
				me.closeOthers = typeof $('[data-modal=' + modal_id + ']').data('ontop') !== 'undefined' ? false : true;
				// Wait till HTML rendered
				setTimeout(function(){me.open();}, 300);
			}
			
			// Attach modal launchers
			me.addListeners();			
		});			
	},
	
	addListeners: function(){
		var me = this;
		
		$(document).off('click.openModal-' + me.id).on('click.openModal-' + me.id, '[data-modal=' + me.id + ']', function(e){
			e.preventDefault();
			// Set close other modals with data=ontop
			me.closeOthers = typeof $(this).data('ontop') !== 'undefined' ? false : true;
			
			var actions = $(this).data('action') != undefined ? $(this).data('action') : 'toggleShow';
			me[actions]();			
		});

		$(window).on('resize orientationchange', function () {
			me.fixBlur();
		});
		
		$(document).on('modal.open', function(e, modal_id){
			if (modal_id === id) me.open();
		});
	},

	onModalLoaded: function(){
		var me = this;

		// Modal close
		me.targt.find('[data-action=modalClose]').on('click', function(){
			
			// This is not working properly
//			if (me.addToHistory && !MIGOA.ui.isChromeMobile()) {
//				history.back();
//			} else {
//				me.close();
//			}
			me.close();
		});

		var transitionEnd = 'transitionend webkitTransitionEnd otransitionend MSTransitionEnd';

		me.targt.on(transitionEnd, function(){
			if (!me.targt.hasClass('modal-in')) me.targt.removeClass('md-showModal');
			me.targt.removeClass('modal-animating');
			
			me.animating = false;
			
			// Hide all (TEST for mobile performance)
			//if (me.opened && MIGOA.ui.isMobile()) me.targt.siblings('.section, footer').hide();
		});

		//Capture submit action
		me.targt.on('submit', 'form', $.proxy(me.onSend, me));
		
		// Set submit button
		if (me.targt.find('input[type=submit]').length > 0) {
			me.submitButton = me.targt.find('input[type=submit]');
			me.submitText = me.submitButton.val();
		}
		
		// After load event
		me.afterLoad();
		
		// Open id set
		
	},	

	toggleShow : function(){
		var me = this;

		me.targt.hasClass('md-show') ? me.close() : me.open();
	},

	/*
	 * Close Modal
	 *
	 * @hideOverlay (boolean:true)
	*/ 
	close : function($super, hideOverlay){
		$super();
		var me = this;

		if (!me.opened) return false;
		
		if (me.addToHistory) $(window).trigger('md.close', [me]);
		
		me.opened = false;

		me.targt.removeClass('md-show modal-in modal-animating');
		
		if(me.animating) me.targt.removeClass('md-showModal');
		
		me.animating = true;
		
		// Hide all (TEST for mobile performance)
		//if (MIGOA.ui.isMobile()) me.targt.siblings('.section, footer').show();

		// Default value: true -> close overlay on close
		hideOverlay = typeof hideOverlay !== 'undefined' ? hideOverlay : true;

		var overlay = MIGOA.page.overlay;

		// Show under overlay modals next level persistance
		for (x in MIGOA.page.modals){
			var modal = MIGOA.page.modals[x];
			if (modal.opened && (modal.persistance + 1)  == overlay.persistance) modal.targt.removeClass('under-overlay');
		}

		// Before closing overlay (if required) check persistance
		if(hideOverlay)	overlay.persistance < 1 ? overlay.overlayHide() : overlay.persistance--;
		
		
		// Block body scroll
		if (me.blockScrollOnPage) $('body').removeClass('body-scroll-blocked');

		// Reset persistance
		me.persistance = 0;
    
    // Clear autoClose Timmer
    if (me._autoCloseTimmer !== null) {
      clearTimeout(me._autoCloseTimmer);
      me._autoCloseTimmer = null;
    }
    
    me.afterClose();
	  
	    
	},

	anyOpen: function() {
		var me = this;
		for (x in MIGOA.page.modals){
			var modal = MIGOA.page.modals[x];
			if (modal.opened) return true;
		}
		
		return false;
	},
	
	open : function($super){
		$super();
		var me = this;

		if (me.opened) return false;
		
		if (me.addToHistory) $(window).trigger('md.open', [me]);
		
		// Close others
		if (me.closeOthers || !me.anyOpen()) {
			for (x in MIGOA.page.modals){
				var modal = MIGOA.page.modals[x];
				if (modal.opened) modal.close(false);
			}
		}else{
			// Send to underoverlay in multimodal opened
			for (x in MIGOA.page.modals){
				var modal = MIGOA.page.modals[x];
				if (modal.opened) modal.targt.addClass('under-overlay');
			}
			if (me.overlay) MIGOA.page.overlay.persistance++;
			me.persistance = MIGOA.page.overlay.persistance;
		}

		me.opened = true;

		me.targt.addClass('md-show md-showModal modal-in modal-animating');
		
		// Modal is full page?
		if (!me.full) me.targt.addClass('md-top'); 		
		
		if (me.position != null) {
			if (me.position == 'BOTTOM') me.targt.addClass('md-bottom');
		}
		
		me.animating = true;

		//Add scrollbar if longer
		if (me.full) me.targt.find('.body').outerHeight(me.getMaxHeight());

		// Block body scroll
		if (me.blockScrollOnPage) $('body').addClass('body-scroll-blocked');	

		// Close Menu
		var mobileMenu = MIGOA.page.mobileMenu;
		if (mobileMenu && mobileMenu.opened) mobileMenu.closeMenu(false);

		if (me.overlay) MIGOA.page.overlay.overlayShow();

		// After opened callback
		me.afterOpen();
		
		//Chrome Blur Bug FIX
		me.fixBlur();
		
//		MIGOA.page.loadFacebook = true;
//		MIGOA.page.initializeFacebook();
    
	    // Auto Close Timmer
	    if (me.autoClose !== null) {
	      clearTimeout(me._autoCloseTimmer);
	      
	      me._autoCloseTimmer = setTimeout(function(){       
	        me.close();
	        me._autoCloseTimmer = null;      
	      }, me.autoClose);
	    }
	    
//	    if (me.addToHistory) {
//	    	window.location.hash = me.id;
//	    	setTimeout(function() {
//	    		$(window).on('hashchange.modal.' + me.id, function(){
//	    			me.close();    		
//	    		});
//	    	}, 200);	    	
//	    }
	    
	},

	getMaxHeight : function(){
		var me = this;

		var max = window.innerHeight - me.targt.find('.header').outerHeight();
		var modalHeight = me.targt.height();

		return  modalHeight > max ? max : 'auto';
	},

	onSend: function(e){		
		e.preventDefault();
		var me = this;
		
		if (me.submitButton != null){
			me.submitButton.addClass('disabled');
			new migoa.components.Spinner(me.submitButton, {size: 18, append:true});
		}

	},

	onResponse: function(response){
		var me = this;
		me.submitButton.removeClass('disabled').val(me.submitText);
		me.submitButton.next('.spinner').remove();
		
		// Is modal response init?
		if (MIGOA.page.modals['ajax-response'] !== undefined){
			// Set content
			MIGOA.page.modals['ajax-response'].setContent('<p>' + response.text + '</p>');
			MIGOA.page.modals['ajax-response'].open();
		}else{
			
			// Init and set
			MIGOA.page.modals['ajax-response'] = new migoa.components.modal.AjaxResponse(response);
		}
		
		me.fixBlur();
	},



	/***************************/
	/*** CHROME BLUR BUG FIX ***/
	/***************************/
	fixBlur: function(){
//		var me = this;
//		
//		me.targt.css('height', '');
//		
//		var modalHeight = me.targt.outerHeight();		
//		if (modalHeight % 2 != 0) me.targt.outerHeight(modalHeight + 1);
	},
	
	setContent: function(content){
		var me = this;
		me.targt.find('.md-content .body').html(content);
	},
	
	closeAll: function() {
		var me = this;
		
		$.each(MIGOA.page.modals, function(){
			if (this.opened) this.close();
		});
		
	},

	afterOpen: function(){
		var me = this;
	},

	afterClose: function(){
		var me = this;
	},

});