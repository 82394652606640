namespace('migoa.components');

migoa.components.Autocomplete = Class.create(migoa.Component, {

	serviceUrl: null,
	minChars : 3,
	innerHtml : null,
	wrapper: null,
	query : '',
	hint : '',
	data : {},
	list : null,
	status: false,
	clearOnKeyPress: false,
	
	_selected: null,
	_focused: null,
	_readyToSubmit: false,
	
	onSend: function(){},
	
	lastValue: '',

	clearedValue: '',
	clearedQuery: '',
	clearedHint: '',
	clearedData: {},
	clearedHtml: '',

	/* constructor */
	initialize : function(id, isMobile) {
		var me = this;
		me.serviceUrl = MIGOA.page.languagePrefix + '/_ajax/autocomplete';
		me.el = jQuery(id);
		me.elHint = jQuery(id.replace("#","#hint_"));
		me.isMobile = isMobile;

		//Generate ul list
		me.list = $("<ul />", {"class": "oa-autolist", "style": "display:none"});

		//Get parent wrapper and append ul
		me.wrapper = me.el.parent('.wrapper');
		me.wrapper.append(me.list);

		me.list = me.wrapper.find('.oa-autolist');

		// Events
		me.el.on('keyup', $.proxy(me.onKeyUp, me));
		me.el.on('keydown', $.proxy(me.onKeyDown, me));

		// Open list on input click
		me.el.on('click', function() {
			if (me.isMobile) {
				setTimeout(() => MIGOA.ui.scrollTo(me.el), 200);
			}
			if (me.status) {
				me.open();
				me.clearOnKeyPress=true;
			} else {
				me.clear();
			}
		});

		// Select item on list click
		me.list.on('click', 'li.item', $.proxy(me.clickOption, me));

		// Close list on outside click
		$(document).on('click', function(event) {
			if (!$(event.target).closest(me.el).length) {
				me.close();
				me.clearOnKeyPress = false;
			}
		});
		
		me.setLastValue( me.el.data('original') );
	},

	getData : function(query){
		var me = this;

		jQuery.get(this.serviceUrl, {query:query}, function(text) { 
			me.data=eval('(' + text + ')'); 
			me.status = true; 
			me.renderResults(); 
		}, 'text');
	},

	onKeyUp : function(e){
      var me = this;

      // Close on ESC keyboard
      if (e.keyCode == 27) {
      	me._restoreBackup();
      	return me.close();
      }
      
      // Not search if keyboard arrows
      if (e.keyCode == 40 || e.keyCode == 38) return;

      // Keyboard enter
      if (e.keyCode == 13) {
      	me._selected = me._focused;
      	
      	if(me._focused.length == 0) {
      		me.onSend();
      		me.setLastValue( me.el.val() );
      	}
      	
      	if (me._readyToSubmit) {
      		me.onSend(); 
      		me.setLastValue( me.el.val() );
      	} else {
      		me.setOption();
      	}

      	return;
      }

	  me.query = me.el.val();
	  me.hint = me.elHint.val();

      // Min chars control
      if (me.minChars > me.query.length) return me.close();

      me.getData(me.query);
		
	},

	onKeyDown : function(e){
		var me = this;
		me._focused = me.list.find('li.item.focused');

		// First arrow click to go to items list
		if (me._focused.length === 0 && (e.keyCode == 40 || e.keyCode == 38)){
			if (e.keyCode == 40) me._focused = me.list.find('li.item:first').addClass('focused');
			if (e.keyCode == 38) me._focused = me.list.find('li.item:last').addClass('focused');
			
			return false;
		}
		
		if (e.keyCode == 40) {		
			var next = me._focused.nextAll('li.item:first');
			if (next.length > 0) {
				me._focused.removeClass('focused');
				me._focused = next;
				me._focused.addClass('focused');
			}else{
				me._focused.removeClass('focused');
				me._focused = null;
			}

			return false;
			
		} else if (e.keyCode == 38) {        
			var prev = me._focused.prevAll('li.item:first');
			if (prev.length != '') {
				me._focused.removeClass('focused');
				me._focused = prev;
				me._focused.addClass('focused');
			}else{
				me._focused.removeClass('focused');
				me._focused = null;				
			}

			return false;
		}
		
		// Clear input on first char keypress
	  	if (me.clearOnKeyPress) {
	  		if (e.keyCode != 27) {
	  			me.clear();
	  		}
	  	}
		
	},

	renderResults: function(){
		var me = this;
		me.innerHtml = '';

		if (me.data.suggestions.length == '') return me.close();

	    me.feelSuggestByType('CITY');
	    me.feelSuggestByType('STATE');
	    me.feelSuggestByType('COUNTY');
	    me.feelSuggestByType('REGION');
	    me.feelSuggestByType('COUNTRY');
		me.feelSuggestByType('PRODUCT');
	    me.feelSuggestByType('DISTRICT');
	    me.feelSuggestByType('PLACE');

		me.list.html(me.innerHtml);//.find('li.item:first').addClass('focused');
		
		me.open();
	},

	feelSuggestByType: function(type) {
		var me = this;
		var added = false;
		var icon = {
			COUNTRY: 'icon-flag',
			REGION: 'icon-compass',
			STATE: 'icon-compass',
			COUNTY: 'icon-compass',
			CITY: 'icon-pin-location',
			DISTRICT: 'icon-signpost',
			PLACE: 'icon-map',
			PRODUCT: 'icon-home'
		};
		
		if(me.data.suggestions[type] != undefined && me.data.suggestions[type].length > 0) {
			added = true;
			me.innerHtml += '<li class="title"><i class="icon ' + icon[type] + '"></i><strong>' + me.data.geonameTypeNames[type] + '</strong></li>';
	
			me.data.suggestions[type].forEach(function(place) {
				
				var span = place.name.replace(new RegExp(me.query, "gi"), '<strong>$&</strong>'); 
				
				me.innerHtml += '<li class="item" data-query="'+place.name +'" data-hint="'+place.hint+'"><div>' + span;
				if (place.productNumber != null) {
					me.innerHtml += '<span class="number">' + place.productNumber + '</span>';
				}
				me.innerHtml += '</div></li>';
	
			});
		}
	},
	
	clickOption : function(e){
		var me = this;
		me._selected = $(e.currentTarget);

		me.setOption();
	},

	setOption : function(){
		var me = this;
		
		if (me._selected.length !== 0) {
			me.hint = me._selected.attr('data-hint');
			me.query = me._selected.attr('data-query');
		}

		// Hide list
		me.close();

		// Set input value & focus
		me.el.val(me.query);
		me.el.focus();		

		// Set input value
		me.elHint.val(me.hint);

		// Set focus next empty element or submit				
		if (!MIGOA.page.components['datepicker'].from) {
			setTimeout(function() {MIGOA.page.components['datepicker'].fromInput.datepicker('show')}, 200);
		} else if (MIGOA.page.components['datepicker'].toInput && !MIGOA.page.components['datepicker'].to) {
			setTimeout(function() {MIGOA.page.components['datepicker'].toInput.datepicker('show')}, 200);
		}
		
		me._forgetBackup();
	},
	
	open: function(){
		var me = this;
		
		me._readyToSubmit = false;
		
		if( me.list.html() != '') me.list.show();
	},
	
	close: function(){
		var me = this;
		me._readyToSubmit = true;
		
		me.list.hide();		
	},
	
	clear: function(){
		var me = this;
		me._backupValues();
  		me.clearOnKeyPress=false;
	},
	
	getValue: function() {
		var me = this;
		return me.el.val();
	},

	hasValue: function() {
		var me = this;
		return me.getValue() != '';
	},

	getLastValue: function() {
		var me = this;
		return me.lastValue;
	},

	setLastValue: function(val) {
		var me = this;
		me.lastValue=val;
	},
	
	hasChanged: function() {
		var me = this;
		return me.getValue() != me.getLastValue();
	},
	
	_backupValues: function() {
		var me = this;
	
		me.clearedValue = me.el.val();
		me.clearedQuery = me.query;
		me.clearedHint = me.hint;
		me.clearedData = me.data;
		me.clearedHtml = me.list.html();
		
  		me.el.val('');
  		me.query='';
		me.hint='';
  		me.data={};
  		me.list.html('');
	},
	
	_restoreBackup: function() {
		var me = this;

		if (me.clearedValue != '') {
	  		me.el.val(me.clearedValue);
	  		me.query=me.clearedQuery;
			me.hint=me.clearedHint;
	  		me.data=me.clearedData;
	  		me.list.html(me.clearedHtml);
	  		
	  		me._forgetBackup();
	  	}
	},
	
	_forgetBackup: function() {
		var me=this;
		
		me.clearedValue = '';
		me.clearedQuery = '';
		me.clearedHint = '';
		me.clearedData = {};
		me.clearedHtml = '';
	}

});