namespace('migoa.pages');

migoa.pages.Home = Class.create(migoa.pages.Generic, {
	calendar: null,

	/* constructor */
	initialize: function($super,isBasePathBased,productSegment,segmentPrefix, calendar) {
		$super(isBasePathBased,productSegment,segmentPrefix);
		this.calendar = calendar;
	},

	/* Overrided */
	onDocumentLoaded: function($super) {
		var me = this;
		$super();

		me.initPageSearchComponents();
		
		me.initAso();

		setTimeout(function() { me.initHeaderCarousel(); }, 1500);
	},
	
	initPageSearchComponents: function() {
		var me = this;
		
		if ($('#fdestinationName').length) {
			me.components['destinationInputComp'] = new migoa.components.Autocomplete('#fdestinationName', false);
			me.components['destinationInputComp'].onSend = function() {
				me.submitSearch();
			};
		}
		if ($('#drop-destinations').length) {
			me.dropdowns['dropDestinations'] = new migoa.components.Dropdown('drop-destinations');
			me.dropdowns['dropDestinations'].afterSetOption = function($selected, value) {
				me.submitSearch();
			};
		}
		
		me.components['datepicker'] = new migoa.components.DatePicker('#fcalformat-arr', '#fcalformat-go', me.calendar);
		
		me.dropdowns['dropAdults'] = new migoa.components.Dropdown('drop-adults');
		me.dropdowns['dropChildren'] = new migoa.components.Dropdown('drop-children');			
		me.dropdowns['dropChildrenAges'] = new migoa.components.dropdown.Ages('drop-children-ages');
		me.dropdowns['dropChildren'].afterSetOption = function($selected, value) {
			me.dropdowns['dropChildrenAges'].changeNumberValue(value);
		}; 		
	},

	initAso: function() {
		var me = this;
		me.aso = new migoa.components.aso.Home(me.productSegment == 'ACTIVITY' ? 'd' : 'n');
	},
	
	submitSearch: function() {
		var me = this;
		me.aso.search();			
	},

	initHeaderCarousel: function() {
		var me = this;

        var swiper = new Swiper('.swiper-container', {
	      centeredSlides: true,
	      effect: 'fade',
	      speed: 1500,
	      autoplay: {
	        delay: 7000
	      }
	    });
	}

});