namespace('migoa.components.aso');

migoa.components.aso.Home = Class.create(migoa.components.Aso, {
	
	/* constructor */
	initialize: function($super, datesType) {
		$super(datesType);
	},

	getSearchString: function(){
		var me = this;
		
		if (typeof MIGOA.page.dropdowns['dropDestinations'] !== 'undefined') {
			return MIGOA.page.dropdowns['dropDestinations'].getSelectedValue();
		}
		if (typeof MIGOA.page.components['destinationInputComp'] !== 'undefined') {
			return MIGOA.page.components['destinationInputComp'].el.val();
		}
		
		return null;
	},

	getHint: function(){
		var me = this;
		
		if (typeof MIGOA.page.dropdowns['dropDestinations'] !== 'undefined') {
			return MIGOA.page.dropdowns['dropDestinations'].getSelectedValue();
		}
		if (typeof MIGOA.page.components['destinationInputComp'] !== 'undefined') {
			return MIGOA.page.components['destinationInputComp'].elHint.val();
		}

		return null;
	},

	buildAsoParams: function($super) {
		var asoParams = $super();
		var me = this;

		var searchString = me.getSearchString();
		var hint = me.getHint();

		var moreParams = {
			s: searchString,
			hint: hint
		};

		$.extend(asoParams, moreParams);
		
		asoParams = me._cleanAsoParams(asoParams);
		
		return asoParams;
	},
	
	_beforeSearch: function(asoParams) {
		var me = this;					

		// destination is required	
 		if (asoParams['s'] == null || asoParams['s'] == "") {
 			MIGOA.page.components['destinationInputComp'].el.focus();
 			return false;
 		}

		// dates are required
 		if (asoParams['date'] == null || asoParams['length'] == null) {
	 		if (asoParams['date'] == null) {
 				setTimeout(function() {MIGOA.page.components['datepicker'].fromInput.datepicker('show')}, 200);
	 		} else if(me.datesType == 'n') {
 				setTimeout(function() {MIGOA.page.components['datepicker'].toInput.datepicker('show')}, 200);
	 		}
	 		return false;
		}

		// always redirect to a full new url (list version)
		var url = MIGOA.page.languagePrefix + '/_ajax/urlformcreator';
	
		// post to urlformcreator and redirect to received url...
		$.ajax({
			url: url,
			data: asoParams,
			method: "POST"
		})
		.done(function(data) {
			window.location.assign(data);
		});
		
		return false;
	}
	
});