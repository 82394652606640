namespace('migoa.components');

migoa.components.FlashMessage = Class.create(migoa.Component, {

	id : null,
	position: 'top right',
	element: null,
	itemSettings: {
		icon: null,
		type: 'info',
		text: 'Test',
		content: null,
		closeButton: false,
		onClose: function(){},
		shown: false,
	},
	showOnAppend: true,
	duration: 5000,
	autoClose: true,
	fullWidth: false,
	fade: true,

	/* constructor */
	initialize : function(options) {
		var me = this;			
		
		if (typeof options === 'object') me = $.extend(true, me, options);
		me.id = me.id != null ? me.id : '0';
		var container = me.fullWidth ? 'container-fluid' : 'container';
		
		me.element = $('<div id="flash-' + me.id  + '" class="flash-' + me.id + ' flash-wrapper fixed ' + me.position + '"><div class="' + container + '"><div class="flash-list"></div></div></div>');
		
		$('body').append(me.element);
			
	},

	show: function(item, message){
		var me = this;
		
		me.element.show();
		
		if (!item.shown) {
			
			me.fade ? message.fadeIn() : message.show();
		}		
		
		item.shown = true;			
				
	},
	
	appendFlash: function(options){
		var me = this, htmlContent;
		
		if (typeof options === 'string') options = {text: options};
		
		var item = (options !== 'undefined') ? $.extend(true, {}, me.itemSettings, options ) : me.itemSettings;
		
		if (item.content === null) {
			htmlContent = '<i class="icon icon-' + item.icon + '"></i>' + item.text;
		} else {
			htmlContent = item.content;
		}
		
		var closeButtonHTML = '';
		
		if (item.closeButton) {
			closeButtonHTML = '<i class="icon-delete close" data-action="close"></div>';
		}
		
		var message = $('<div class="flash flash-' + item.type + '">' + htmlContent + closeButtonHTML + '</div>');
		
		me.element.find('.flash-list').append(message);
		
		// Show on append
		if (me.showOnAppend) me.show(item, message);
		
		// Fade out and remove
		if (me.autoClose) setTimeout(function(){ 
				message.fadeOut(1000, function(){
					message.remove();
					if (me.element.find('.flash-list').html() === "") me.close(message);
				});
			}, me.duration);
		
		// Add close action to X
		if (item.closeButton) {
			message.on('click', '[data-action=close]', function(){
				item.onClose();
				me.close(item, message);				
			});
		}
		
		return message;
	},
	
	removeMessage: function(message){
		var me = this;
		message.remove();
		if (me.element.find('.flash-list').html() === "") me.close(message);
	},
	
	close: function(item, message){
		var me = this;
		
		if (item.shown) {
			me.fade ? message.fadeOut() : message.hide();
		}
		
		if (me.element.find('.flash:visible').length === 0) me.element.hide();
		
		item.shown = false;		
	}




});