namespace('migoa.components');

migoa.components.WithCalendar = Class.create(migoa.Component, {
	calendar: null,

	/* constructor */
	initialize : function(calendar) {
		var me = this;
		me.calendar = calendar;
	},

	// validate availability days from from date and param day
	canCheckout: function(day){
		var me = this;
		if (me.from) {
			for (var d = new Date(me.from); d < day; d.setDate(d.getDate() + 1)) {
				if (!me.getAvailability(d)) {
						return false;
				}
			}
		}

		return true;
	},

	// validate minstay days from from date and param day
	validateMinStay: function(day){
		var me = this;
		if (me.from && me.getMinStayNights(me.from) > 0) {
			var minDate = new Date(me.from);
			minDate.setDate(minDate.getDate() + me.getMinStayNights(me.from));
			if (+minDate > +day) return true;
		}

		return false;
	},

	// get month informatio from calendar
	getCalendarMonth: function(day){
		var me = this;

		if (me.calendar) {
			if (me.calendar.months) {
				
				let monthKey = ""+day.getFullYear()+("0" + (day.getMonth() + 1)).slice(-2);
				return me.calendar.months[monthKey];
			}
		}

		return null;
	},

	// get minstay from desired day
	getMinStayNights: function(day) {
		var me = this;
		let calendarMonth = me.getCalendarMonth(day);
		if (!calendarMonth) return null;
		
		let dayKey = day.getDate()-1;
		return calendarMonth.minStay[dayKey];
	},
	
	// get checkin statys from desired day
	getCheckinStatus: function(day) {
		var me = this;
		let calendarMonth = me.getCalendarMonth(day);
		if (!calendarMonth) return false;
		
		let dayKey = day.getDate()-1;
		return calendarMonth.checkIn[dayKey];
	},
	
	// get chekcout from desired day
	getCheckoutStatus: function(day) {
		var me = this;
		let calendarMonth = me.getCalendarMonth(day);
		if (!calendarMonth) return false;
		
		let dayKey = day.getDate()-1;
		return calendarMonth.checkOut[dayKey];
	},
	
	// get availability from desired day
	getAvailability: function(day) {
		var me = this;
		let calendarMonth = me.getCalendarMonth(day);
		if (!calendarMonth) return false;
		
		let dayKey = day.getDate()-1;
		return calendarMonth.available[dayKey];
	}


});
