/*
 * migoa generic Component
 * 
 * A Component is a self-managed part of a Page
 * 
 */

namespace('migoa');

migoa.Component = Class.create({
	params: null,
	init: false,
	currentOpen: null,
	
	initialize: function(){		
		this.params = Array.prototype.slice.call(arguments);
		this.init = true;
		
	},
	
	hasLostDOM: function() {
		var me = this;
		
		if (typeof me.element === 'undefined') return false;
		
		if (!me.element.isInDOM()) return true;
		
		return false;
		
	},
	
	refresh: function() {
		var me = this;
		
		// Allow instance refresh	
		if (me.hasLostDOM()) {
			me.init = false;
			me.initialize.apply(this, me.params);
		}	else {
			return;
		}		
	},
	
	open: function(){
		var me = this;
		
		if (MIGOA.page.currentOpen == me.id) return;
		
		// Any other open
		if (MIGOA.page.currentOpen != null) {
			$(window).off('hashchange');	
		}
		
		MIGOA.page.currentOpen = me.id;
		
		
	},
	
	close: function(){
		var me = this;
		
		if(MIGOA.page.currentOpen == null) return;
		
		MIGOA.page.currentOpen = null;
	}
	
});
