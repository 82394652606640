namespace('migoa.components.modal');

migoa.components.modal.AjaxResponse = Class.create(migoa.components.Modals, {
	
	/* Override */
	initialize: function($super, response) {
		
		// Response
		// Object {text: "Please enter a password that contains 5 to 15 digits (letters and/or numbers).", error: "PASSWORD_WRONG_LENGTH", status: "0"}
		
		if ($("#modal-ajax-response").length < 1)
			$('body').append('<div class="md-modal md-ajax" id="modal-ajax-response"><div class="md-content"><div class="header"><i class="icon icon-delete pull-right md-close" data-action="modalClose"></i><a href="#" class="mobile-logo-gray"></a></div><div class="body"></div></div>');
		
		//Init response ajax modal
		$super('ajax-response');
		
		var me = this;
		
		me.setContent('<p>' + response.text + '</p>');
		me.targt.show(function(){
			me.open();
		});
	}
});