/*
 * Google Analytics integration
 *  
 */

var dataLayer = dataLayer || [];

namespace('migoa.google');

migoa.google.Analytics = Class.create({
	
	managerCompany: null,
	
	setManagerCompany: function(company) {
		this.managerCompany=company;
	  	//_gaq.push(['mainTracker._setCustomVar', 1, 'company', company, 1]);  // visitor-level scope
	},
	
	trackSimilarAdsClick: function() {
		this.trackEvent('Similar Ads', 'Click Ad');
	},

	trackSimilarAdsCallback: function(numberInPage) {
		this.trackEvent('Similar Ads', 'Ajax Open', 'Position ' + numberInPage);
	},
	
	trackFilter: function(filter, label) {
		this.trackEvent('Filters', filter, label);
	},
	
	trackMapPinClick: function() {
		this.trackEvent('Map', 'Pin Click');
	},
	
	trackOrganicClick: function(label) {
		this.trackEvent('Organic Ad', 'Click', label);
	},

	trackCalendarUnavailable: function(action, label) {
		label="errorType:"+label+"\nUrl:"+document.location.href;
		this.trackEvent('Calendar Unavailable', action, label);
	},

	trackIndexAdClick: function() {
		this.trackEvent('Index Ad', 'Click');
	},
	
	trackVisitedAd: function() {
		this.trackEvent('Visited Ad', 'Click');
	},
	
	trackSortInSearch: function(direction) {
		this.trackEvent('Sort', direction);
	},
	
	trackDetailPageChapter: function(chapter) {
		this.trackEvent('Detail', chapter);
	},

	trackReservationButton: function() {
		this.trackEvent('Detail', 'Reservation');
	},
	
	trackSavedAds: function() {
		this.trackEvent('Save and Share', 'My Ads');
	},
	
	trackIntegratedMap: function() {
		this.trackEvent('Map', 'Ad Click');
	},
	
	trackCalendarSearch: function(value) {
		if (value != null && value > 0) {
			this.trackEvent('SERP', 'Calendar Search', 'Average Final Price', value);
		}		
	},
	
	trackEvent: function(category, action, label, value) {
		if (label != null && value != null) {
			value = Math.round(value);
			dataLayer.push({
				'event': 'custom',
				'eventCategory': category,
				'eventAction': action,
				'eventLabel': label,
				'eventValue': value
			});
		} else if (label != null) {
			dataLayer.push({
				'event': 'custom',
				'eventCategory': category,
				'eventAction': action,
				'eventLabel': label
			});
		} else {
			dataLayer.push({
				'event': 'custom',
				'eventCategory': category,
				'eventAction': action
			});
		}
	}

});
