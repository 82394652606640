namespace('migoa.components.modal');

migoa.components.modal.LoginCommon = Class.create(migoa.components.modal.Cdr, {

	valSubmit : null,
	keyRecaptcha : null,
	googleLoaded : false,
	
	/* override */
	afterOpen: function($super){
		$super();
		var me = this;
		me.keyRecaptcha = jQuery("#login_form").children('input[name=token]').data('key-recaptcha');
		if(!me.googleLoaded) {
			me.loadRecaptchaScript('https://www.google.com/recaptcha/api.js?render='+me.keyRecaptcha, function() {
				me.googleLoaded = true;
			});
		}
	},

	/* override */
	onSend: function($super, e){
		e.preventDefault();
		var me = this;
		
		var form = jQuery("#login_form");
		if(form.valid()) {
			if(me.googleLoaded) {
				grecaptcha.ready(function() {
					grecaptcha.execute(me.keyRecaptcha, {action: 'submit'}).then(function(token) {
						form.children('input[name=token]').val(token);
						$super(e);
						jQuery.ajax({
							type: "POST",
							dataType: "json",
							xhrFields: {withCredentials: true},
							url: MIGOA.settings.cdrGlobalGesUrl+me.loginPath,
							data: form.serialize(),  
							success: function(response) {
								me.onResponse(response);
							}
						});
					});
				});
			} else {
				setTimeout(function(){ me.onSend(e); }, 500);
			}
		}
	},
	
	/* override */
	onResponse: function($super, response){
		var me = this;

		me.resetSubmitButton();
		
		if (response.success && response.success == 1) {
			MIGOA.page.userSession.getInfoUser();
			me.close();
		} else {
			// Is modal response init?
			me.targt.find('.error_login').find('.txt').text(me.getErrorText(response));
			me.targt.find('.error_login').show();
			me.fixBlur();
		}
	},

	loadRecaptchaScript: function(url, callback) {
	    jQuery.ajax({
			url: url,
			dataType: 'script',
			success: callback,
			async: true
		});
	},

	resetSubmitButton: function() {
		var me = this;
		me.submitButton.removeClass('disabled').val(me.submitText);
		me.submitButton.next('.spinner').remove();
	}

});