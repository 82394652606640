/* Custom validators for the jQuery validator plugin
 * The message values are added in jquery.validate.locales.js*/

/* validate value with regexp with attriubte 'pattern' from element */
jQuery.validator.addMethod("pattern",function(value,element){
	var ok=true;
	var input = jQuery(element);
	var re = new RegExp(input.attr('pattern'));
	if(re != null) {
		if(input.val() != '' && !input.val().match(re)) {
			ok = false;
		}
	}
	return ok;
});

//Custom email validator. Only ASCII characters. see WEB-449
jQuery.validator.addMethod("email", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(value);
});

jQuery.validator.addMethod("password", function(value, element) {
	return true;
    // return this.optional(element) || /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=\-_?\.])(?=\S+$).{8,}$/i.test(value);
});