namespace('migoa.components');

migoa.components.Overlay = Class.create(migoa.Component, {

	visible : false,
	el : null,
	persistance : 0,
	animating: false,

	/* constructor */
	initialize : function(overlay_id) {
		var me = this;
		
		me.el = $(overlay_id);

		var transitionEnd = 'transitionend webkitTransitionEnd otransitionend MSTransitionEnd';
		
		me.el.on(transitionEnd, function(){
			
			if (!me.visible) me.el.removeClass('overlay-in');
			me.el.removeClass('overlay-show overlay-hide');
			
			me.animating = false;
		});

	   	me.el.on('click', function(e){
	      e.stopPropagation();
	      e.preventDefault();

	      	// Close modals depending on persistance
			if (me.persistance < 1) {

				// Close all (except full-screen-loader)
				for (var modal in MIGOA.page.modals) {
					if (modal != 'full-screen-loader') {
						MIGOA.page.modals[modal].close(true);
						me.overlayHide();
					}
				}

			} else {

				//Close modals with current persistance
				for(var modal in MIGOA.page.modals) {
					if (MIGOA.page.modals[modal].persistance == me.persistance)
						MIGOA.page.modals[modal].close(false);
				}

				me.persistance--;
			}

	        // Close Menu
	        if (MIGOA.page.mobileMenu && MIGOA.page.mobileMenu.open) MIGOA.page.mobileMenu.closeMenu(true);
	        
			for (x in MIGOA.page.dropdowns){
				var drop = MIGOA.page.dropdowns[x];
				if (drop.opened) drop.close();
			}
			
			//me.overlayHide();
		});			

	},

	overlayShow : function(){
		var me = this;
		
		if (me.visible) return false;
		
		me.visible = true;
		
		klass = 'overlay-show overlay-in';
		
		me.el.addClass(klass);

		me.animating = true;
		
		$('body').addClass('body-scroll-blocked');
	},

	overlayHide : function(){
		var me = this;
		
		
		if (!me.visible) return false; 

		me.visible = false;
		
		klass = 'overlay-hide';

		me.el.addClass(klass);
		
		if(me.animating) me.el.attr('class', 'md-overlay');
		
		me.animating = true;
		
		$('body').removeClass('body-scroll-blocked');			

	}

});