namespace('migoa.components.modal');

migoa.components.modal.Cdr = Class.create(migoa.components.Modals, {

	resetPassPath: 'regenerate',
	loginPath: 'client/login',
	signupPath: 'client/cliente/alta',

	initialize : function($super, modal_id, options) {
		var me = this;
		$super(modal_id, options);
	},

	onModalLoaded: function($super){
		$super();
		var me = this;
	},	
	
	afterOpen: function($super){
		$super();
		jQuery('#resetpass').attr('href',MIGOA.settings.cdrUrl+this.resetPassPath);
	},

	getErrorText: function(response) {
		let text = [];
		if(response.error_barra) {
			text.push(response.error_barra);
		}

		if(response.errores) {
			response.errores.forEach(function(error) {
				text.push(error.mensaje);
			});
		}

		return text.join('<br>');
	}
});