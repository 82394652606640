namespace('migoa.components.modal');

migoa.components.modal.Contact = Class.create(migoa.components.Modals, {

	/* override */
	open : function($super){
		$super();
		MIGOA.analytics.trackEvent('Modal', 'CONTACT', 'open');
	},
	
});