/*
 * migoa Storage component. Uses HTML5 Storage and Cookie fallback (optionally)
 *
 */

namespace('migoa');

migoa.Storage = Class.create({
	
	useCookiesFallback: false,
	ID_STORAGE_SEARCH_ITEMS: 'ad-search-items',
	ID_STORAGE_SEARCH: 'ad-search',
	MAX_STORAGE_SEARCHES : 150,
	cookies : {},
	
	/* constructor */
	initialize : function(useCookies) {
		this.useCookiesFallback = useCookies;
		this.setCookies();
	},

	getLocalStorage: function(){
		if(typeof(Storage) === "undefined") {
			//some fallback here?
			return null;
		} else {
			return localStorage;
		}
	},
	
	getSessionStorage: function(){
		if(typeof(Storage) === "undefined") {
			//some fallback here?
			return null;
		} else {
			return sessionStorage;
		}
	},

	setLocalValue: function(key, value, allowCookie){
		var me = this;
		var storage = me.getLocalStorage();
		me._setStorageValue(storage, key, value, allowCookie);
	},
	
	setSessionValue: function(key, value, allowCookie){
		var me = this;
		var storage = me.getSessionStorage();
		me._setStorageValue(storage, key, value, allowCookie);
	},
	
	getLocalValue: function(key){
		var me = this;
		var storage = me.getLocalStorage();
		return me._getStorageValue(storage, key);
	},
	
	getSessionValue: function(key){
		var me = this;
		var storage = me.getSessionStorage();
		return me._getStorageValue(storage, key);
	},
	

	/*private functions*/
	_setStorageValue: function(storage, key, value, allowCookie){
		var me = this;
		
		if(typeof(allowCookie) === 'undefined'){ allowCookie = true; } //to use the default value

		if(storage!=null){
			try {
				storage[key] = value;
			} catch (e) {
				storage.clear();
				try {
					storage[key] = value;
				} catch (e) {
					return false;
				}				
			}				
		} else {
			if(me.useCookiesFallback && allowCookie){
				me._setCookieValue(key, value);
			}
		}
	},
	
	_getStorageValue: function(storage, key){
		var me = this;
		if(storage!=null){
			var value = storage[key];
			if(value!=null && typeof(storage[key]) !== 'undefined'){
				return value;
			}
		} else {
			if(me.useCookiesFallback){
				return me._getCookieValue(key);
			}
		}
		return null;
	},
	
	_setCookieValue: function(key, value){
		jQuery.cookie(key, value, {path: '/'});
	},
	
	_setCookieValuePath: function(key, value, path){
		jQuery.cookie(key, value, {path: path});
	},
	
	_getCookieValue: function(key) {
		return jQuery.cookie(key);
	},
	
	setCookies: function() {
		var me = this;
		var pairs = document.cookie.split(";");
		me.cookies = {};
		for (var i=0; i<pairs.length; i++){
		var pair = pairs[i].split("=");
			me.cookies[pair[0].replace(/^\s*/, '')] = unescape(pair[1]);
		}
	},

	getCookieValue: function(key) {
		var me = this;
		return me.cookies[key];
	},
	
	extractParamsFromStorage: function(productId){
		var me = this;
		
		var searches = JSON.parse(me.getLocalValue(me.ID_STORAGE_SEARCH));
		
		if (searches == null) return;
		
		var query = searches[productId];
		
		if (query) {
			return MIGOA.ui.urlParamsToObject(query);
		}
		
		return false;

	},

	cloneParamsFromStorage: function(productId, toProductId){
		var me = this;
		
		var searches = JSON.parse(me.getLocalValue(me.ID_STORAGE_SEARCH));
		
		if (searches == null) return;
		
		var query = searches[productId];
		
		me.addParamsToStorage(toProductId, query);
	},

	addParamsToStorage: function(id, query) {
		var me = this;
		
		var items = MIGOA.storage.getLocalValue(me.ID_STORAGE_SEARCH_ITEMS);
		if (items != null && items.length > 0) {
			items = JSON.parse(items);
		} else {
			items = [];
		}
		
		var searches = MIGOA.storage.getLocalValue(me.ID_STORAGE_SEARCH);
		if (searches != null && searches.length > 0) {
			searches = JSON.parse(searches);
		} else {
			searches = {};
		}
		
		if (searches[id] == null) {
			// add a new item
			searches[id] = query;
			items.unshift(id);

			// if we have too many items, forget the oldest one
			if (Object.keys(searches).length > me.MAX_STORAGE_SEARCHES) {
				var removed = items.pop();
				delete searches[removed];
			}
		} else {
			// just update the existing value
			searches[id] = query;
		}
		
		MIGOA.storage.setLocalValue(me.ID_STORAGE_SEARCH, JSON.stringify(searches));
		MIGOA.storage.setLocalValue(me.ID_STORAGE_SEARCH_ITEMS, JSON.stringify(items));
	}

});