
/*
 * Global namespace declaration function.
 * It must be used before declaring any Class, to make sure the namespace is already defined.
 */

function namespace(name) {
	var ns = name.split('.'), o=window, i, len;
	for(i=0, len=ns.length; i<len; i++){
		o = o[ns[i]] = o[ns[i]] || {};
	}
}
