namespace('migoa.components');

migoa.components.Dropdown = Class.create(migoa.Component, {

	opened : false,
	text : null,
	defaultText: null,
	options : new Object(),
	selected : new Object(),
	selectedValue: null,
	element : null,
	isMultioption: false,
	input: null,
	submitOnSelect: false,
	dropDownMenuHeight: null,
	formTarget: false,
	afterSetOption: function () { },
	menuPosition: 'left',
	afterInit: function(){},
	dropMenu: null,

	/* constructor */
	initialize : function($super, id, settings) {
		$super(id, settings);
		var me = this;
		
		me.element = $('#' + id);
		
		if (!me.element.length) { return; }  // do nothing else if we can't find element by id
		
		if (typeof settings !== 'undefined') $.extend(true, me, settings);
		
		//Multioptions enabled
		me.isMultioption = me.element.hasClass('dropdown-multiselect');

		// Visible text
		me.text = me.element.find('[data-drop=option]');
		
		//Default text
		me.defaultText = me.element.attr("data-text-default");
		if(me.defaultText == "") {
			me.defaultText = me.text.text();
			
		}
		
		// Menu dropdown
		me.dropMenu = me.element.find('.dropdown-menu');
		
		// Menu position
		me.dropMenu.addClass('dropdown-menu-' + me.menuPosition);
		
		// Selectable options
		me.options = me.element.find('.dropdown-menu li');
		
		// Any options?
		if (me.options.length == 0) {
			me.disable();
		}
		
		// Any selected?
		me.selected = me.options.filter('.active');
		
		if (me.selected.length > 0){
			
			if (!me.isMultioption){
				
				if (me.selected.length > 0) me.selectedValue = $(me.selected[0]).data('value');			
			
			}else{
				
				me.selectedValue = '';
				
				for (x in me.selected) {
					me.selectedValue += x;
				}
				
				me.selectedValue.slice(0,1);
			}
			
		}
		
		
		// Dropdown Menu height
		me.element.find('.dropdown-menu').height();

		// Add to options on click event
		if (me.isMultioption){
			me.options.off('click.dropdownOption').on('click.dropdownOption', 'input', $.proxy(me.selectMultiOption, me));
		}else{
			me.options.off('click.dropdownOption').on('click.dropdownOption', $.proxy(me.selectOption, me));
		}
		
		// Form and input attachments
		if (typeof me.element.data('form') === 'undefined'){
			
			// Associated hidden input
			me.input = $('input[name=' + me.element.data('input') + ']');
			
			// Associated form
			me.formTarget = me.input.closest('form');
		}else{
			
			// Associated form
			me.formTarget = $('form[name=' + me.element.data('form') + ']');
			
			// Associated hidden input
			me.input = me.formTarget.find('input[name=' + me.element.data('input') + ']');			
		}

		me.element.off('click.dropdownOpen').on('click.dropdownOpen', '.dropdown-toggle', $.proxy(function(e){
			e.preventDefault();
			
			me.opened ? me.close(e) : me.open();

		}, me));

		// Close on outside click
		$(document).on('click', function(event) {
			if (!($(event.target).closest(me.element).length > 0)) {
				me.close(event);
				event.stopPropagation();
			}
		});
		
		// Events
		$(me.element).on('keydown', $.proxy(me.arrowkey, me));
		
		me.afterInit();
	},

	open: function(){
		var me = this;
		
		// Disabled dropdown
		if (me.element.hasClass('disabled')) return false;
		
		//Close others
		for (x in MIGOA.page.dropdowns){
			var drop = MIGOA.page.dropdowns[x];
			if (drop.opened) drop.close();
		}

		// Avoid menu overflow
		if (me.menuPosition == 'left' && me.element.offset().left  + me.dropMenu.width() > $('body').width()) {
			me.dropMenu.css('left', -(me.menuPosition == 'left' && me.element.offset().left + me.dropMenu.width() + 2 - $('body').width()));	
		}
		
		me.element.addClass('open');
		me.opened = true;
	},

	close: function(e){
		var me = this;
		me.element.removeClass('open');
		me.element.find('li.focused').removeClass('focused');
		me.opened = false;
	},

	selectOption: function(e){
		var me = this;

		me.selected = $(e.delegateTarget);
		if (!jQuery(me.selected).hasClass('disclaimer')) {
			me.selectedValue = me.selected.data('value');
	
			me.setOption();
		}
	},

	selectMultiOption: function(e){
		var me = this;

		var currentSelection = $(e.delegateTarget);
		var currentKey = currentSelection.data('value');

		// Add or delete option from selected options
		if (currentKey in me.selected){
			delete me.selected[currentSelection.data('value')];
		}else{
			me.selected[currentKey] = currentSelection;
		}

		currentSelection.toggleClass('active');

		// Update input (multioption) HIDDEN FORM
		me.input.val(function(){
			
			var values = '';

			for (x in me.selected){
				values += me.selected[x].data('value') + ',';
			}

			return values.slice(0, -1);
		});
		
		me.selectedValue = '';
		for (x in me.selected) {
			me.selectedValue += x + ',';
		}
		
		me.selectedValue = me.selectedValue.slice(0, -1);		
		
		// Auto submit on select
		// if (me.submitOnSelect) me.formTarget.submit();		
	},
	
	setOption: function(optionValue, executeCallback){
		var me = this;
		if (typeof executeCallback === 'undefined') executeCallback = true;
		
		// Select option
		if (typeof optionValue !== 'undefined') {
			me.selected = me.options.filter('[data-value=' + optionValue +']');
			me.selectedValue = optionValue;
		}
		
		// Check if event before select option
		if (!me.beforeSetOption(me.selected)) return false;		
		
		me.selected.addClass('active').siblings('li').removeClass('active');

		me._updateText();
		
		me._updateInput();
		
		if (executeCallback) {
			me.close();					
			me.afterSetOption(me.selected, me.selectedValue);			
		}
		

	},
	
	_updateInput: function() {
		var me = this;
		
		// Seting input > has data-value? else text
		me.selectedValue = me.selected.data('value');
		me.input.val(me.selectedValue);
		
		// Not in use
		//me.input.val(typeof attr !== typeof undefined && attr !== false ? attr : me.selected.text().replace(/\D+/g, ''));
	},
	
	_updateText: function(){
		var me = this;
		
		// Setting text > has data-text? else text
		var attr = me.selected.data('text');
		var isHtml = me.text.data('isHtml');
		if(typeof isHtml !== typeof undefined && isHtml !== false) {
			me.text.text(typeof attr !== typeof undefined && attr !== false ? attr : me.selected.text());
		} else {
			me.text.html(typeof attr !== typeof undefined && attr !== false ? attr : me.selected.text());
		}		
	},

	arrowkey : function(e){
		var me = this;
		me._focused = me.element.find('li.focused').length > 0 ? me.element.find('li.focused') : me.element.find('li.active');
		
		if (me._focused.length == 0) me._focused = me.element.find('.dropdown-menu li:first').addClass('focused');
		
		if (e.keyCode == 40) {        
			var next = me._focused.next('li');
			if (next.length > 0) {
				me._focused.removeClass('focused');
				me._focused = next;
				me._focused.addClass('focused');
				
				
			}

		  return false;
		} else if (e.keyCode == 38) {        
			var prev = me._focused.prev('li');
			if (prev.length != '') {
				me._focused.removeClass('focused');
				me._focused = prev;
				me._focused.addClass('focused');
			}

			return false;
		}
		
		if (e.keyCode == 13) {
			me.selected = me._focused;
			me.setOption();
			return;
		}
	},
	
	beforeSetOption: function($option){
		return true;
	},
	
	enable: function (option) {
		var me = this;
		
		if (me.options.length === 0) {
			me.element.addClass('disabled');
			return;
		}
		
		if (typeof option === 'undefined'){
			me.element.removeClass('disabled');
		} else {
			me.options.each(function(){
				var currentOption = $(this);
				if(currentOption.attr('data-value') == option) {
					currentOption.removeClass('disabled');
				}
			});
		}
	},

	disable: function (option) {
		var me = this;
		
		if (me.options.length === 0) {
			me.element.addClass('disabled');
			return;
		}
		
		if (typeof option === 'undefined'){
			me.element.addClass('disabled');
		} else {
			me.options.each(function(){
				var currentOption = $(this);
				if(currentOption.attr('data-value') == option) {
					currentOption.addClass('disabled');
				}
			});
		}
	},
	
	resetDefault: function() {
		var me = this;
		me.options.each(function(){
			var currentOption = $(this);
			currentOption.removeClass('active');
			currentOption.children("input").attr('checked', false);
		});
		me.selectedValue = null;
		me.text.text(me.defaultText);
		me.selected = me.options.filter('.active');
	},
	
	getSelectedValue: function() {
		var me = this;
		return me.selectedValue;
	}

});