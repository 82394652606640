(function($) {
	$.fn.extend({
	  
	    /* Has event attached? */
		/* Example: $('#test').hasevent('click', 'test')
		 * Docs: Only valid for events previously attached by JQuery 'on' method
		 */    
		hasEvent: function(eventName, eventNamespace) {
			
			if (typeof eventNamespace === 'undefined') eventNamespace = '';
			
			if ($(this).length < 1) return false;
			
			var events = $._data($(this)[0], 'events');
			
			if (typeof events === 'undefined') return false;						
			
			if (typeof events[eventName] === 'undefined') return false;
			
			for (x in events[eventName]) {
				if (events[eventName][x].namespace == eventNamespace) return true;
			}
			
			return false;
		},
			
	    /* Just one event launch */
		/* Protection for multiple events launch (Ex: Scroll)
		 * Example: $('#test').onDebounce('click' [, time], callback)
		 */		
		onDebounce: function(event, time, callback) {
			
			if (typeof time !== 'number'){
				return this.onDebounce(event, 200, time);
			}
								
			var timeout = false;
			
			this.on(event, function(e){
	       		
	       		if (timeout) return;
	       		
	       		timeout = true;
	       		
	       		setTimeout(function(){
	       			callback(e);
	       			timeout = false;
	       		}, time);
	       		
			});
		},
		
	    /* Visible in viewport */
	    isInViewport: function(callback){
	
	      var rect = this[0].getBoundingClientRect();
	
	      if (rect.height === 0 && rect.width === 0) return false;
	      
	      var isInViewPort = rect.top + rect.height + 100 >= 0 && rect.top <= $(window).height();
	
	      if (typeof callback !== 'undefined' && isInViewPort) callback();
	
	      return isInViewPort;
	    },
	    
	    /* Change font size depending on div width */
	    fitText: function(increment) {
	      
	    	if (this.length === 0) return;
	    	
			// Do not make font bigger by default
			if(typeof increment === 'undefined') increment = false;
			
			var maxWidth = this.data('maxWidth') || this.parent().width(); 
			
			var css = {
				whiteSpace: this.css('white-space'),
				display: this.css('display'),
				fontSize: this.css('font-size').slice(0,-2)
			};
			
			this.css({
				whiteSpace: 'nowrap',
				display: 'inline-block',
			});
			
			var width = this.width();
			var ratio = maxWidth / width;
			
			if (!increment && ratio >= 1) return; 
			
			css.fontSize = Math.floor(ratio * css.fontSize);
			
			this.css(css);
		},
		/** Is element in the DOM */
		isInDOM: function($container) {
			
			if (typeof this === 'undefined') return false;
			
			if (typeof $container === 'undefined') $container = document.documentElement;
			
			return $.contains($container, this[0]);
		}
		
	});
})(jQuery);

/* 
 * Event on bootstrap size change
 * Example: $.whenSize('md', callback);
 */

jQuery.extend({
	whenSize: function(size, callback) {
		var callbackExecute = true;
		
		if (!$(window).hasEvent('resize', 'size.' + size)) {		
			
			var executeCallback = function() {
				
				var sizes = {
					'xs': $(document).width() < 768,
					'sm': $(document).width() > 767 && $(document).width() < 1025,
					'md': $(document).width() > 1024 && $(document).width() < 1200,
					'lg': $(document).width() > 1199,						
				};					
				
				if (sizes[size] && typeof callback !== 'undefined') {					
					if (callbackExecute) callback();					
					callbackExecute = false;
				} else {					
					callbackExecute = true;
				}
				
				return sizes[size];
				
			};
			
			$(window).on('resize.size.' + size, function(){														
				executeCallback();
			});
			
			return executeCallback();		
			
			
		};
	}	
});
