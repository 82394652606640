/*
 * migoa User Interface helper
 * 
 * This class provides some helper functions to manipulate UI elements
 * 
 */

namespace('migoa');

migoa.UI = Class.create({
	
	navigateTo: function(url, server) {
		if (server != null) {
			window.location.assign(url.replace('%server%', server));
		} else {
			window.location.assign(url);
		}
	},
	
	navigateToInNewWindow: function(url, server) {
		if (server != null) {
			window.open(url.replace('%server%', server));
		} else {
			window.open(url);
		}
	},

	toggleExpandable: function(targetDiv, actionLink) {
		if (targetDiv.visible()) {
			targetDiv.expanded = false;
			targetDiv.hide();
			actionLink.addClassName('closed');
			return false;
		} else {
			targetDiv.expanded = true;
			targetDiv.show();
			actionLink.removeClassName('closed');
			return true;
		}
	},
	
	toggleReadMore: function(id) {
		var parent = $(id);
		if (parent) {
		
			var text = parent.find('.collapsed-text');
			var readMore = parent.find('.read-more');			
			
			if (text) { text.toggleClass('active'); }
			if (readMore) { readMore.toggleClass('active'); }
		}
	},
	
	visitAd: function(productId) {
		jQuery('#ad_'+productId).addClass('visited-ad');
	},
	
	addParameterToURL: function(key, value) {
		var me = this;
        var newUrl = me._generateUrlParameter(document.location.search, key, value);
        me.reloadPage(newUrl);
    },
	
	_generateUrlParameter: function(url, key, value){
		key = escape(key);
        value = escape(value);
        var newUrl = '';

        var kvp = url.substr(1).split('&');
        if (kvp == '') {
            newUrl = '?' + key + '=' + value;
        } else {

            var i = kvp.length; var x; while (i--) {
                x = kvp[i].split('=');
                if (x[0] == key) {
                    x[1] = value;
                    kvp[i] = x.join('=');
                    break;
                }
            }

            if (i < 0) {
            	kvp[kvp.length] = [key, value].join('=');
            }

            newUrl = '?'+kvp.join('&');
        }
        return newUrl;
	},
	
    reloadPage: function(newUrl){
    	document.location.search = newUrl;
    },
    
    reloadFullPage: function(newUrl){
    	document.location.href = newUrl;
    },
    
    getUrlParamValue: function(param, query) {
    	
      if (typeof query === 'undefined') query = window.location.search.substring(1);	

      if (query[0] === '?') query = query.substring(1);
      
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == param){return pair[1];}
       }
       return(null);
    },
    
    urlParamsToObject: function(query) {
    	var me = this;
		// remove any preceding url and split
    	query = query.substring(query.indexOf('?')+1).split('&');
		var params = {}, pair, d = decodeURIComponent, i;
		// march and parse
		for (i = query.length; i > 0;) {
			pair = query[--i].split('=');
	    
			// create object if param name is "param.subparam"
			if (pair[0].indexOf('.') > -1) {
				if (typeof params[d(pair[0]).split('.')[0]] === 'undefined') {
					var obj = {};
					obj[d(pair[0]).split('.')[0]] = {};
					obj[d(pair[0]).split('.')[0]][d(pair[0]).split('.')[1]] = d(pair[1]);
					$.extend(true, params, obj);
				} else {
					params[d(pair[0]).split('.')[0]][d(pair[0]).split('.')[1]] = d(pair[1]);
				}
			} else {
				params[d(pair[0])] = d(pair[1]);
			}
		}
	  
		return params;    	
    },
    
    isMobile: function(){
    	return $(document).width() < 768;
    },
    
    isTablet: function(){
    	return $(document).width() < 1025;
    },
    
    isTabletVertical: function(){
    	return $(document).width() == 768;
    },     
    
    isIPhone: function(){
    	return (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i));    	
    },
    
    isIPad: function(){
    	return navigator.userAgent.match(/iPad/i);    	
    },
    
    isChromeMobile: function() {
		return navigator.userAgent.includes("CriOS") || (navigator.userAgent.includes("Chrome") && navigator.userAgent.includes("Android"));
	},
  
	isIPhoneChrome: function() {
		return this.isChromeMobile() && this.isIPhone();
	},
    
    isInViewport: function($element){
    	var rect = $element[0].getBoundingClientRect();
    	return rect.top + rect.height + 100 >= 0 && rect.top <= $(window).height();
    },
  
  
	/* Get all links with data-link-params="true" and update with new search params */  
	updateLinksParams: function(aso, container){
  	
		if (container == null) container = ''; 
		
	    // If has date params
	    if (aso.hasDates()) {
	      
	      $(container + ' [data-link-params=true]').each(function() {
	      
	      	var excluded = [];
	      	var type = 'full';
	      	var newUrl = '';
	      	var protect = [];
	      	
	        // Add protected params (To force keep always) data-protect=""
	        if (typeof $(this).data('protect') !== 'undefined') {        	
	        	protect = $(this).data('protect').split(' ');
	        }
	        
	        // Remove excluded params
	        if (typeof $(this).data('excluded') !== 'undefined') {        	
	        	excluded = $(this).data('excluded').split(' ');
	        }        
	      	
	        // data-link-type can be 'full' or 'dates' (default: full)
	        if (typeof $(this).data('link-type') !== 'undefined') {        	
	        	type = $(this).data('link-type');
	        }
	        
	        var a = document.createElement('a');
	        if ($(this).is('a')) a.href = $(this).attr('href');
	        if ($(this).is('button')) a.href = $(this).attr('data-href');
	        if ($(this).is('option')) a.href = $(this).val();
	        
	        // Remove hash
	        var url = a.hash === '' ? a.href : a.origin + a.pathname + a.search;
	        
	        if (type == 'dates') {
	        	newUrl = aso.buildAsoUrlDatesOnly(url, protect);
	        } else {
	        	newUrl = aso.buildAsoUrl(url, excluded, protect);
	        }
	        
	        // Add hash
	        if (a.hash !== '') newUrl = newUrl + a.hash;
	        
	        // Store params in browser storage to remember them when going directly to details page without url params
	        a.href = newUrl;
	        var storageParams = a.search + a.hash
	        var productId = $(this).parents('.property').attr('data-id');
	        if (productId != null && storageParams != null && storageParams != '') {
				MIGOA.storage.addParamsToStorage(productId, storageParams);
			}
	        
	        if ($(this).is('a')) {
	       		$(this).attr('href', newUrl);
	       		
	        } else if($(this).is('option')){
	        	$(this).attr('value', newUrl);
	        	
	        } else if($(this).is('button')){
	        	$(this).attr('data-href', newUrl);
	        }
	
	      });
	    }
	},
  
  	
	orderArrayByKey: function(arr, key, key2){
		arr.sort(function( a, b ) {
			if (a[key] > b[key]) {
				return 1;
			}else if(a[key] < b[key]){
				return -1;
			} else {
				
				if (typeof key2 === 'undefined') return 0;
				
				if(a[key2] > b[key2]){
					return 1;
				}else if(a[key2] < b[key2]){
					return -1;
				}else{
					return 0;
				}			
			}
				
		});
	},
  
	dateAsString: function(day) {	
		if (typeof day === 'undefined' || !day) return;
	
		return day.getFullYear() + '-' + ('0' + (day.getMonth() + 1)).substr(-2) + '-' + ('0' + day.getDate()).substr(-2);
	},
  
	indexOfObjectByKeyValue: function(arr, key, value) {
	  	for (var x = 0; x < arr.length; x++) {
	  		if (arr[x][key] === value) return x;
	  	}
	  	return -1;
	},
  
	isUrlValid: function(url) {
    	return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
	},
  
	translate: function() {
	  	
	  	if (arguments[0] == null) return false;  	
	
	  	var key = arguments[0];  	
	  	if (MIGOA.page.translations[key] == null) {
	  		console.error('Translation key not loaded: ' + key);
	  		return key + ' key not found';
	  	}
	  	
	  	var text = MIGOA.page.translations[key];
	  	
	  	if (arguments.length > 1) {
	  		for (var x = 1; x < arguments.length; x++) {
	  			text = text.replace('$' + x, arguments[x]);
	  		}
	  	}
	  	
	  	return text; 
	},
  
	toLink: function(text) {
		var a = document.createElement('a');
		a.href = text;
		return a;
	},
  
	scrollTo: function(targetId, scrollElement, correction) {
		var $scrollElement, currentScroll = 0;
		  
		if (typeof scrollElement === 'number') {
			correction = scrollElement;
			delete scrollElement;
		}
		if (typeof correction === 'undefined') correction = 0;
		if (typeof scrollElement === 'undefined') {
			  
			$scrollElement = $('html, body');
			  
			// Fixed navBar hides part of html
			if (correction === 0 && MIGOA.ui.isMobile()) correction = -$('.navBar.mobile').outerHeight();		  
		} else {
			$scrollElement = $(scrollElement);
			  
			// if is div, add scroll value
			currentScroll = $(scrollElement).scrollTop();
		}
		  
		var scrollTop = $(targetId).offset().top - $scrollElement.offset().top + currentScroll + correction;
		  
		$scrollElement.animate({scrollTop: scrollTop}, 600); 
	},
  
	decodePaxesType: function(param) {
		var me = this, paramArray = [];	  

		$.each(param.split(','), function(i, subParam){
			var dummyPax={}, subParam = subParam.split(':');		  
			let key = subParam[0];
			dummyPax[key] = subParam[1] ? subParam[1] : null;
			paramArray.push(dummyPax);
		});
		  
		return paramArray;
	}
		
});