namespace('migoa.components');

migoa.components.DatePicker = Class.create(migoa.components.WithCalendar, {

  defaultOptions: null,
  options: null,
  fromInput: null,
  from: false,
  toInput: null,
  to: false,
  activeInput: null,
  activeTarget: null,
  extended: false,
  
  toButton: null,
  fromButton: null,
  
  hoverRangeActive: false,
  tempRange: [],
  tempRangeCorrect: true,
  resetTempRange: false,
  
  selectedRange: [],
  
  isSettingArrival: true,
  
  inst: null,
  
  submitOnSelect: false,
  callMethod: null,
  
  serverFormat: "dd/mm/yy",
  
  afterClearDates: function(){},
  
  afterSelectDates: function(){},
  
  range: true,
  
  showCalendarFooter: true,
	today: new Date(),

	/* constructor */
	initialize : function($super, from_id, to_id, calendar, options, callMethod) {
		$super(calendar);
		var me = this;
		me.fromInput = $(from_id);
		me.toInput = $(to_id);
		me.callMethod = callMethod;
		me.today.setHours(0,0,0,0);

		// Default options
		me.defaultOptions = {
			minDate: new Date(),
			beforeShow: $.proxy(me.beforeShow, me), 
			beforeShowDay: $.proxy(me.beforeShowDay, me),
			onSelect: $.proxy(me.onSelect, me),
			onClose: $.proxy(me.onClose, me),
			afterShow: $.proxy(me.afterShow, me),
			duration: 300,
			showAnim: "slideDown",
			hoverDayOnOpen: false,
			showOn: 'button',
			buttonText: 'Choose',
			maxDate: '12M'
		};

		// Merge custom options and override them if nedded
		me.options = $.extend( {}, me.defaultOptions, options );
		
		var tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		var toOptions = $.extend( {}, me.options, { minDate: tomorrow } );
		
		// Set locales
		var langRegion = $('html').attr('lang');
		jQuery.datepicker.setDefaults(jQuery.datepicker.regional[langRegion]);
		
		me.fromInput.datepicker(me.options);
		me.toInput.datepicker(toOptions);
		
		// Alter datepickers if url  has start and end dates
		me._initRangeFromUrl();
		
		me.fromButton = me.fromInput.next('.ui-datepicker-trigger');
		me.toButton = me.toInput.next('.ui-datepicker-trigger');
		
		me.fromButton.on('click', function(e){
			e.preventDefault();
		});
	
	},

	
	/****************************************************/
	/************ DATEPICKER EXTENDED METHODS ***********/
	/****************************************************/

	beforeShow : function(input, obj){
		var me = this;		
		
		// Close autocomplete destination input
		if (typeof MIGOA.page.components['destinationInputComp'] !== 'undefined') MIGOA.page.components['destinationInputComp'].close();
		
		// Setting arrival o depart?
		me.isSettingArrival = obj.input.attr('id') == me.fromInput.attr('id') ? true : false;
		
		// Force open checkin before checkout
		if ( !me.isSettingArrival && !me.from) {
			setTimeout(function() {
				me.fromInput.datepicker('show');
			}, 500);
			return;
		}		
		
		// Set default to open
		if (!me.from && !me.to){
			me.fromInput.datepicker("option", {defaultDate: null});
			me.toInput.datepicker("option", {defaultDate: null});
		}
		if (me.isSettingArrival) {
			var tempDate = new Date(me.to);
			tempDate.setDate(tempDate.getDate() - 1);
			
			if (!me.from && me.to) me.fromInput.datepicker("option", {defaultDate: tempDate});
		}else{
			var tempDate = new Date(me.from);
			tempDate.setDate(tempDate.getDate() + 1);			
			
			if (!me.to && me.from) me.toInput.datepicker("option", {defaultDate: tempDate});
		}		
		
		// Class
		if (me.range) {
			me.isSettingArrival ? obj.dpDiv.addClass('dp-checkin').removeClass('dp-checkout') : obj.dpDiv.addClass('dp-checkout').removeClass('dp-checkin');			
		}
		
		me.activeInput = obj.input;

		if (MIGOA.ui.isMobile() && me.activeInput.closest('.container').find('.datepicker-mobile-container').length > 0) {			
			// Mobile full width datepicker
			var pickerWrapper = me.activeInput.closest('.container').find('.datepicker-mobile-container');
		}else{
			var pickerWrapper = me.activeInput.closest('[data-content=calendar]').length > 0 ?  me.activeInput.closest('[data-content=calendar]') : $('[data-content=calendar]');
		}			
		
		//Append datepicker to focused input parent wrapper // Avoid datepicker floating		
		obj.dpDiv.appendTo(pickerWrapper);
		
		// Selected nights html
		me._setSelectedNights();
		
		// Any
		if (me.range) {
			if (!me.extended) if (me.isSettingArrival && me.to) me._activateHoverRange();
			if (!me.isSettingArrival && me.from) me._activateHoverRange();			
		}

	},

	beforeShowDay : function(day){
		var me = this;
		
		day.setHours(0,0,0,0);

		var isSelectable = true;
		if (me.calendar) {
			if (me.isSettingArrival) {
				// must be available and checkin
				if (!me.getAvailability(day) || !me.getCheckinStatus(day)) {
					isSelectable = false;
				}
			} else {
				// must be different from date, from date to day must be available, checkout and minstay
				if(me.from && +me.from == +day || !me.canCheckout(day) || !me.getCheckoutStatus(day) || me.validateMinStay(day)) {
					isSelectable = false;
				}
			}
		}

		var classes = 'day-date-' + (+day);
		var tooltip = false;
		
		// Past date
		if (me.isSettingArrival) {
			if (+me.today > +day) return [false, classes + ' day-past', tooltip];
		} else {
			if(me.from){				
				var minDate = new Date(me.from);
				minDate.setHours(0,0,0,0);
				if (+minDate > +day) return [false, classes + ' day-past', tooltip];
			}			
		}
		
		// Selected Day?
		if ($.inArray(+day, me.selectedRange) >= 0) classes += ' day-selected';
			
		return [isSelectable, classes, tooltip];
		
	},
	
	onSelect: function(day, picker){			
		var me = this;
		if (me.isSettingArrival){
			me.from = new Date(picker.selectedYear, picker.selectedMonth, picker.selectedDay);
			
			//Set default date for the other datepicker if no set
			//if (!me.to) me.toInput.datepicker("option", {defaultDate: me.from});
			
//			if(me.callMethod != undefined && me.callMethod != null) {
//				me.callMethod(me.activeInput.data('input'), MIGOA.ui.dateAsString(me.from));
//			} else {
//				// Set from or to input hidden date value
//				var inputHidden = $("input[name='" + me.activeInput.data('input') + "']");
//				inputHidden.val(MIGOA.ui.dateAsString(me.from));
//			}
			
			// Reset calendar if
			if (me.to || (me.toInput && me.toInput[0] && me.toInput[0].value)){
				me.resetCalendar('to');
				me.toInput.trigger('click');
			}

			me.fromInput.removeClass('empty');
			
//			me.fromInput.trigger('oa_calendar.select.from', [me.from, me.to]);
		}else{
			me.to = new Date(picker.selectedYear, picker.selectedMonth, picker.selectedDay);
			
			//Set default date for the other datepicker if no set		
			//if (!me.from) me.fromInput.datepicker("option", {defaultDate: me.to});
			
//			if(me.callMethod != undefined && me.callMethod != null) {
//				me.callMethod(me.activeInput.data('input'), MIGOA.ui.dateAsString(me.to));
//			} else {
//				// Set from or to input hidden date value
//				var inputHidden = $("input[name='" + me.activeInput.data('input') + "']");
//				inputHidden.val(MIGOA.ui.dateAsString(me.to));
//			}
			
			// Reset calendar if
			if (me.from && me.to <= me.from) {
				me.resetCalendar('from');
				me.fromInput.trigger('click');
			}
			
			me.toInput.removeClass('empty');
			
//			me.toInput.trigger('oa_calendar.select.to', [me.from, me.to]);
		}
		
	    if(me.tempRange.length > 0)
	        me.selectedRange = me.tempRange;
	    
	    if (me.submitOnSelect) {
	    	if (me.from && me.to) inputHidden.closest('form').submit();
	    }
	    
	    // Open the other calendar   
		if (me.isSettingArrival && !me.to) setTimeout(function() {me.toInput.datepicker('show'); me.toInput.focus(); }, 500);
		if (!me.isSettingArrival && !me.from) setTimeout(function() {me.fromInput.datepicker('show'); me.fromInput.focus(); }, 500);
	  
		me.afterSelectDates();
		

	},
	
	onClose: function(){
		var me = this;
		
		if (me.hoverRangeActive) me._desactivateHoverRange();
	},
	
	afterShow: function(stringDate, inst){
		var me = this;
		
		me._initDomAttachments(inst.dpDiv);
		
		if (me.showCalendarFooter) {
			me._setSelectedNights();
			
			// Show clear nights
			me.from || me.to ? $('.calendar-footer .clear-dates').show() : $('.calendar-footer .clear-dates').hide();
			me.updateNightsAndClearDatesTexts();			
		}
	},
	
	_initDomAttachments: function(datepickerDOM){
		var me = this;
		
		datepickerDOM.off('click', '.clear-dates a').on('click', '.clear-dates a', $.proxy(me._clearDates, me));
		
	},
	
	updateNightsAndClearDatesTexts: function() {
		var me = this;
		if (MIGOA != null && MIGOA.page != null) {
			jQuery('.ui-datepicker .clear-dates a').text(MIGOA.page.getTranslation('clear_dates'));
			jQuery('.ui-datepicker .nights.pull-left .text').text(MIGOA.page.getTranslation('nights') + ' ');
		}
	},
	
	/* Helpers */
	
	_activateHoverRange: function(){
		
		var me = this;
		me.hoverRangeActive = true;  

	    //limit = _getTimeUTC(_strToDate(limit));
	    var hoverPostion = false;
	    var rangeStatus = 'ok';

	    // Set html nights message
	    me._setSelectedNights();
	      
	    //Añadimos el mouseover para crear el rango dinámico
	    $('.ui-datepicker').on('mouseover touchend', 'td .ui-state-default', function () {


	      //Obtenemos la fecha (integer) del dia en que estamos hover mediante la clase
	      hoverPostion = $(this).parent().attr('class').match(/day-date-(.*)/)[1].split(' ')[0];

	      // Temp range activated
	      me.resetTempRange = true;

	      //Reset temp range
	      me.tempRange = [];      
	      
	      //Creamos la array de dias seleccionados temporales (tempRange)
	      me._addRangeDays(parseInt(hoverPostion));

	        
	      //Recorremos todos los dias
	      $('.ui-datepicker').find('td .ui-state-default').each(function(){

	        //Obtenemos el valor en integer de cada una de las fechas que vamos recorriendo
	        var position = $(this).parent().attr('class').match(/day-date-(.*)/)[1].split(' ')[0];

	        //Si el dia (this) tiene su position dentro del tempRange añadimos la classe active, si no la quitamos
	        if($.inArray(parseInt(position), me.tempRange) >= 0){
	          $(this).parent().addClass('day-active');
	          
	        }else{
	        	$(this).parent().removeClass('day-active');
	        }
	        me.isSettingArrival ? $(this).parent().removeClass('day-from day-selected') : $(this).parent().removeClass('day-to day-selected');

	      });

	      
	      // Set html nights
	      me._setSelectedNights();

	    });

	    // Si sacamos el cursor del calendario ponemos el range donde estaba
	    $('.ui-datepicker').on('mouseleave', 'td.day-off, tbody', $.proxy(me._clearTempRange, me));
	    $('.ui-datepicker').on('mouseover', '.ui-state-disabled', $.proxy(me._clearTempRange, me));
		
	},
	
	_desactivateHoverRange: function(){
		
		$('.ui-datepicker').off('mouseover touchend', 'td .ui-state-default');
		$('.ui-datepicker').off('mouseover', '.ui-state-disabled');
		
		var me = this;
		me.hoverRangeActive = false;
		
	},
	
	_addRangeDays: function(hoverPostion){
		var me = this;
	      		
		var limit = me.isSettingArrival ? +me.to : +me.from;
		
		if (me.isSettingArrival){
			while(hoverPostion <= limit){
				me.tempRange.push(hoverPostion);
				    
				// Add days to hover day till find limit
				var tempDate = new Date(hoverPostion);
				hoverPostion = +tempDate.setDate(tempDate.getDate() + 1);
			}			
		}else{
			while(hoverPostion >= limit){
				me.tempRange.push(hoverPostion);
				    
				// Subtract days to hover day till find limit
				var tempDate = new Date(hoverPostion);
				hoverPostion = +tempDate.setDate(tempDate.getDate() - 1);
			}				
		}
		
	},
	
	
	_clearTempRange: function(){
		var me = this;
		
		if (me.resetTempRange) {
			//$('.ui-datepicker .day-active').removeClass('day-active day-error');
			//$('.ui-datepicker .day-cleared').removeClass('day-cleared day-error');
			me.activeInput.datepicker('refresh');
			
		}
		
		me.resetTempRange = false;
		me.tempRange = [];
		me._setSelectedNights();
	},
	
	_initRangeFromUrl: function(){
		var me = this;

		// From url params			 
		if(MIGOA.ui.getUrlParamValue('date') && MIGOA.ui.getUrlParamValue('length')){			
			
			var from = $.datepicker.parseDate('yy-mm-dd', MIGOA.ui.getUrlParamValue('date'));
			const to = new Date();
			to.setDate(from.getDate() + parseInt(MIGOA.ui.getUrlParamValue('length')));
			//var to = $.datepicker.parseDate('yy-mm-dd', buildTodate);
			
			if (+from < +me.today || +from > +to) return;
			
			me.from = from;
			me.fromInput.datepicker('setDate', me.from);
			me.fromInput.removeClass('empty');
			
			me.to = to;
			me.toInput.datepicker('setDate', me.to);
			me.toInput.removeClass('empty');
			
			var day = new Date(me.from);
			while (+day <= +me.to){
				me.selectedRange.push(+day);
				day.setDate(day.getDate() + 1);
			}
		}		
	},
	
	_setSelectedNights: function(){
		var me = this;
		
		var nightsHtml = $('.calendar-footer .nights');
		nightsHtml.hide();
		
		if(me.tempRange.length > 1){
			nightsHtml.find('.num').text(me.tempRange.length - 1);
			nightsHtml.show();
		}else{
			if(me.selectedRange.length > 1){
				nightsHtml.find('.num').text(me.selectedRange.length - 1);
				nightsHtml.show();
			}
		}
		
	},
	
	_clearDates: function(e){
		var me = this;

		e.preventDefault();
		me.resetDates();
		
		me.afterClearDates();
		
	},
	
	
	/**********************************************************************************************************/
	/****************************************** METHODS *******************************************************/
	/**********************************************************************************************************/
	
	
	hasDates:  function(){
		var me = this;
		return me.from != null && me.to != null;
	},
	
	resetDates: function(){
		var me = this;
		
		me.resetCalendar('from');
		me.resetCalendar('to');
	},
	
	resetCalendar: function(calendar){
		var me = this;
		
		me.resetTempRange = false;
		me.tempRange = [];
		me.selectedRange = [];		
		
		var selectedInput = me[calendar + 'Input'];
		
		var temp = me[calendar];
		
		me[calendar] = false;
		
		me._setSelectedNights();
		
		$('.ui-datepicker-calendar td').removeClass('day-active day-selected day-error day-to day-from day-cleared');
		$('.ui-datepicker-calendar td .ui-state-active').removeClass('ui-state-active');
		
		var defaultDay = null;
		if (calendar == 'from' && me.to) defaultDay = me.to;
		if (calendar == 'to' && me.from) defaultDay = me.from;
		
		//selectedInput.datepicker("option", 'defaultDate', temp);
		selectedInput.datepicker("setDate", null);
		
		selectedInput.addClass('empty');
		
		me._desactivateHoverRange();

//		selectedInput.trigger('oa_calendar.reset.' + calendar, [me.from, me.to]);
	},
	
	
	_setDate: function(dayDate, datepicker){
		var me = this;
		datepicker('setDate', dayDate);
		
	},
	
	setDate: function(dayDate, picker){
		var me = this;
		if (dayDate == null) return;
		
		dayDate = new Date(dayDate);
		me[picker].datepicker('setDate', dayDate);
	}

});
