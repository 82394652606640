namespace('migoa');

migoa.MyAds = Class.create({
	
	/*constructor*/
	initialize: function() {
	},
	
	save: function(productId) {
		jQuery.getScript(MIGOA.page.languagePrefix+'/_ajax/favorites.save?productId='+productId);
		MIGOA.analytics.trackEvent('Favorites', 'SAVE', productId);
		return false;
	},
	
	unsave: function(productId) {
		jQuery.getScript(MIGOA.page.languagePrefix+'/_ajax/favorites.unsave?productId='+productId);
		MIGOA.analytics.trackEvent('Favorites', 'UNSAVE', productId);
		return false;
	}
});