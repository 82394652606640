jQuery.validator.allMessages = {
		es: {
			required: "Este campo es obligatorio.",
			remote: "Por favor, rellena este campo.",
			email: "Por favor, escribe una dirección de correo válida",
			url: "Por favor, escribe una URL válida.",
			date: "Por favor, escribe una fecha válida.",
			dateISO: "Por favor, escribe una fecha (ISO) válida.",
			number: "Por favor, escribe un número entero válido.",
			phone: "Por favor, escribe un número de teléfono válido.",
			digits: "Por favor, escribe sólo dígitos.",
			creditcard: "Por favor, escribe un número de tarjeta válido.",
			equalTo: "Por favor, escribe el mismo valor de nuevo.",
			accept: "Por favor, escribe un valor con una extensión aceptada.",
			maxlength: jQuery.validator.format("Por favor, no escribas más de {0} caracteres."),
			minlength: jQuery.validator.format("Por favor, no escribas menos de {0} caracteres."),
			rangelength: jQuery.validator.format("Por favor, escribe un valor entre {0} y {1} caracteres."),
			range: jQuery.validator.format("Por favor, escribe un valor entre {0} y {1}."),
			max: jQuery.validator.format("Por favor, escribe un valor menor o igual a {0}."),
			min: jQuery.validator.format("Por favor, escribe un valor mayor o igual a {0}."),
			reqifdesc: "¡Si escribe una descripción, entonces también tiene que escribir un título!",
			checkRanges: "Las fechas son obligatorias y no pueden coincidir con el inicio/fin de otra temporada",
			checkDates: "Las fechas son obligatorias",
			pattern: "Datos incorrectos",
			password: "Por favor, introduce una contraseña que tenga entre entre 8 y 15 carácteres (al menos una mayúscula, una minúscula, un número y un carácter especial)."
		},
		en: {
			required: "This field is required.",
			remote: "Please fix this field.",
			email: "Please enter a valid email address.",
			url: "Please enter a valid URL.",
			date: "Please enter a valid date.",
			dateISO: "Please enter a valid date (ISO).",
			number: "Please enter a valid number.",
			phone: "Please enter a valid phone number.",
			digits: "Please enter only digits.",
			creditcard: "Please enter a valid credit card number.",
			equalTo: "Please enter the same value again.",
			accept: "Please enter a value with a valid extension.",
			maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
			minlength: jQuery.validator.format("Please enter at least {0} characters."),
			rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
			range: jQuery.validator.format("Please enter a value between {0} and {1}."),
			max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
			min: jQuery.validator.format("Please enter a value greater than or equal to {0}."),
			reqifdesc: "if you provide a description then you also have to provide a title!",
			checkRanges: "The dates are required and can not match the beginning/end of another season",
			checkDates: "The dates are required",
			pattern: "Wrong data",
			password: "Please enter a password that contains 8 to 15 digits (at least one uppercase, one lowercase, one number and one special character)."
		},
		de: {
			required: "Dieses Feld ist ein Pflichtfeld.",
			maxlength: jQuery.validator.format("Geben Sie bitte maximal {0} Zeichen ein."),
			minlength: jQuery.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
			rangelength: jQuery.validator.format("Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein."),
			email: "Geben Sie bitte eine gültige E-Mail Adresse ein.",
			url: "Geben Sie bitte eine gültige URL ein.",
			date: "Bitte geben Sie ein gültiges Datum ein.",
			dateDE: "Bitte geben Sie ein gültiges Datum ein.",
			number: "Geben Sie bitte eine Nummer ein.",
			phone: "Geben Sie bitte eine gültige Telefonnummer ein.",
			digits: "Geben Sie bitte nur Ziffern ein.",
			equalTo: "Bitte denselben Wert wiederholen.",
			range: jQuery.validator.format("Geben Sie bitten einen Wert zwischen {0} und {1}."),
			max: jQuery.validator.format("Geben Sie bitte einen Wert kleiner oder gleich {0} ein."),
			min: jQuery.validator.format("Geben Sie bitte einen Wert größer oder gleich {0} ein."),
			creditcard: "Geben Sie bitte ein gültige Kreditkarten-Nummer ein.",
			reqifdesc: "Wenn Sie eine Beschreibung eingeben, dann müssen Sie auch einen Titel angeben!",
			checkRanges: "Dieser Zeitraum wird benötigt und muss sich von dem Zeitraum anderer Saisonen unterscheiden.",
			checkDates: "Dieser Zeitraum wird benötigt.",
			password: "Please enter a password that contains 8 to 15 digits (at least one uppercase, one lowercase, one number and one special character)."
		},
		it: {
			required: "Campo obbligatorio.",
			remote: "Controlla questo campo.",
			email: "Inserisci un indirizzo email valido.",
			url: "Inserisci un indirizzo web valido.",
			date: "Inserisci una data valida.",
			dateISO: "Inserisci una data valida (ISO).",
			number: "Inserisci un numero valido.",
			phone: "Si prega di inserire un numero di telefono valido.",
			digits: "Inserisci solo numeri.",
			creditcard: "Inserisci un numero di carta di credito valido.",
			equalTo: "Il valore non corrisponde.",
			accept: "Inserisci un valore con un&apos;estensione valida.",
			maxlength: jQuery.validator.format("Non inserire pi&ugrave; di {0} caratteri."),
			minlength: jQuery.validator.format("Inserisci almeno {0} caratteri."),
			rangelength: jQuery.validator.format("Inserisci un valore compreso tra {0} e {1} caratteri."),
			range: jQuery.validator.format("Inserisci un valore compreso tra {0} e {1}."),
			max: jQuery.validator.format("Inserisci un valore minore o uguale a {0}."),
			min: jQuery.validator.format("Inserisci un valore maggiore o uguale a {0}."),
			reqifdesc: "se inserisci una descrizione, devi anche inserire un titolo!",
			checkRanges: "Le date sono obbligatorie e non possono coincidere con l'inizio/fine di un'altra stagione",
			checkDates: "Le date sono obbligatorie",
			password: "Please enter a password that contains 8 to 15 digits (at least one uppercase, one lowercase, one number and one special character)."
		},
		fr: {
			required: "Ce champ est requis.",
			remote: "Veuillez remplir ce champ pour continuer.",
			email: "Veuillez entrer une adresse email valide.",
			url: "Veuillez entrer une URL valide.",
			date: "Veuillez entrer une date valide.",
			dateISO: "Veuillez entrer une date valide (ISO).",
			number: "Veuillez entrer un nombre valide.",
			phone: "S'il vous plaît entrer un numéro de téléphone valide.",
			digits: "Veuillez entrer (seulement) une valeur numérique.",
			creditcard: "Veuillez entrer un numéro de carte de crédit valide.",
			equalTo: "Veuillez entrer une nouvelle fois la même valeur.",
			accept: "Veuillez entrer une valeur avec une extension valide.",
			maxlength: jQuery.validator.format("Veuillez ne pas entrer plus de {0} caractères."),
			minlength: jQuery.validator.format("Veuillez entrer au moins {0} caractères."),
			rangelength: jQuery.validator.format("Veuillez entrer entre {0} et {1} caractères."),
			range: jQuery.validator.format("Veuillez entrer une valeur entre {0} et {1}."),
			max: jQuery.validator.format("Veuillez entrer une valeur inférieure ou égale à {0}."),
			min: jQuery.validator.format("Veuillez entrer une valeur supérieure ou égale à {0}."),
			reqifdesc: "Si vous fourrinissez une description, n'oubliez pas de nous fournir un titre également!",
			checkRanges: "Les dates sont obligatoires et peuvent ne pas correspondre au début / à la fin d'une autre saison",
			checkDates: "Les dates sont obligatoires",
			password: "Please enter a password that contains 8 to 15 digits (at least one uppercase, one lowercase, one number and one special character)."
		},
		pt: {
			required: "Campo de preenchimento obrigat&oacute;rio.",
			remote: "Por favor, corrija este campo.",
			email: "Por favor, introduza um endere&ccedil;o eletr&oacute;nico v&aacute;lido.",
			url: "Por favor, introduza um URL v&aacute;lido.",
			date: "Por favor, introduza uma data v&aacute;lida.",
			dateISO: "Por favor, introduza uma data v&aacute;lida (ISO).",
			number: "Por favor, introduza um n&uacute;mero v&aacute;lido.",
			phone: "Por favor insira um número de telefone válido.",
			digits: "Por favor, introduza apenas d&iacute;gitos.",
			creditcard: "Por favor, introduza um n&uacute;mero de cart&atilde;o de cr&eacute;dito v&aacute;lido.",
			equalTo: "Por favor, introduza de novo o mesmo valor.",
			accept: "Por favor, introduza um ficheiro com uma extens&atilde;o v&aacute;lida.",
			maxlength: jQuery.validator.format("Por favor, n&atilde;o introduza mais do que {0} caracteres."),
			minlength: jQuery.validator.format("Por favor, introduza pelo menos {0} caracteres."),
			rangelength: jQuery.validator.format("Por favor, introduza entre {0} e {1} caracteres."),
			range: jQuery.validator.format("Por favor, introduza um valor entre {0} e {1}."),
			max: jQuery.validator.format("Por favor, introduza um valor menor ou igual a {0}."),
			min: jQuery.validator.format("Por favor, introduza um valor maior ou igual a {0}."),
			reqifdesc: "se você fornecer uma descrição, então você também tem que fornecer um título!",
			checkRanges: "As datas são obrigatórias e não pode coincidir com o início / fim de mais uma temporada",
			checkDates: "As datas são obrigatórias",
			password: "Please enter a password that contains 8 to 15 digits (at least one uppercase, one lowercase, one number and one special character)."
		}
};