namespace('migoa.components.modal');

migoa.components.modal.SignUp = Class.create(migoa.components.modal.Cdr, {

	valSubmit : null,
	
	/* override */
	onModalLoaded: function($super){
		$super();
		var me = this;
		
		me.targt.find('#is_enterprise').change(function(value) {
			if(jQuery(this).prop('checked')) {
				jQuery('#is_enterprise_container').show();
				jQuery('#is_enterprise_container input').addClass('required');
			} else {
				jQuery('#is_enterprise_container').hide();
				jQuery('#is_enterprise_container input').removeClass('required');
			}
		}); 
	},	
	
	/* override */
	onSend: function($super, e){
		e.preventDefault();
		var me = this;
		
		var form = jQuery('#sign_up_form');
		if (form.validate().form()) { //check validations
			form.find("input[name=repeat]").val(form.find("input[name=password]").val());
			$super(e);
			jQuery.ajax({
				type: "POST",
				dataType: "json",
				contentType: "application/json; charset=utf-8",
				xhrFields: {withCredentials: true},
				url: MIGOA.settings.cdrGlobalGesUrl+me.signupPath,
				data: me.formDataToJson(form),
				success: function(response) {
					if(response.success) {
						form.find("input").val("");
						form.find("input[type=checkbox]").attr('checked', false);
						MIGOA.analytics.trackEvent('SignUp', 'CUSTOMER', 'post');
					}
					me.onResponse(response);
				}
			});
		}else{
			me.fixBlur();
		}

	},

	formDataToJson: function(form) {
		var formArray = form.serializeArray();
		//serialize data function
		var returnArray = {};
		for (var i = 0; i < formArray.length; i++){
			let value = formArray[i]['value'];
			if (value === 'true') {
				value = true;
			}
			if (value === 'false') {
				value = false;
			}
			returnArray[formArray[i]['name']] = value;
		}
		return JSON.stringify(returnArray);
	},
	
	/* override */
	onResponse: function($super, response){
		var me = this;

		me.resetSubmitButton();
		
		if (response.success && response.success == 1) {
			MIGOA.page.userSession.getInfoUser();
			me.close();
		} else {
			// Is modal response init?
			me.targt.find('.error_login').find('.txt').text(me.getErrorText(response));
			me.targt.find('.error_login').show();
			me.fixBlur();
		}
	},

	resetSubmitButton: function() {
		var me = this;
		me.submitButton.removeClass('disabled').val(me.submitText);
		me.submitButton.next('.spinner').remove();
	}
	
});