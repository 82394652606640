namespace('migoa.components');

migoa.components.DatePickerSolo = Class.create(migoa.components.WithCalendar, {

  defaultOptions: null,
  options: null,
  fromInput: null,
  from: false,
  toInput: null,
  activeInput: null,
  activeTarget: null,
  extended: false,
  
  inst: null,
  
  callMethod: null,
  
  serverFormat: "dd/mm/yy",
  

	/* constructor */
	initialize : function($super, from_id, calendar, options, callMethod) {
		$super(calendar);
		var me = this;
		me.fromInput = $(from_id);
		me.callMethod = callMethod;

		// Default options
		me.defaultOptions = {
			minDate: new Date(),
			beforeShow: $.proxy(me.beforeShow, me), 
			beforeShowDay: $.proxy(me.beforeShowDay, me),
			onSelect: $.proxy(me.onSelect, me),
			onClose: $.proxy(me.onClose, me),
			afterShow: $.proxy(me.afterShow, me),
			duration: 300,
			showAnim: "slideDown",
			hoverDayOnOpen: false,
			showOn: 'button',
			buttonText: 'Choose',
			maxDate: '12M',
			showButtonPanel: false
		};

		// Merge custom options and override them if nedded
		me.options = $.extend( {}, me.defaultOptions, options );
		
		// Set locales
		var langRegion = $('html').attr('lang');
		jQuery.datepicker.setDefaults(jQuery.datepicker.regional[langRegion]);
		
		me.fromInput.datepicker(me.options);
		
		me.fromButton = me.fromInput.next('.ui-datepicker-trigger');
		me.fromButton.on('click', function(e){
			e.preventDefault();
		});
	
	},

	
	/****************************************************/
	/************ DATEPICKER EXTENDED METHODS ***********/
	/****************************************************/

	beforeShow : function(input, obj){
		var me = this;		
		
		// Set default to open
		if (!me.from){
			me.fromInput.datepicker("option", {defaultDate: null});
		}
		
		me.activeInput = obj.input;

		if (MIGOA.ui.isMobile() && me.activeInput.closest('.container').find('.datepicker-mobile-container').length > 0) {			
			// Mobile full width datepicker
			var pickerWrapper = me.activeInput.closest('.container').find('.datepicker-mobile-container');
		}else{
			var pickerWrapper = me.activeInput.closest('[data-content=calendar]').length > 0 ?  me.activeInput.closest('[data-content=calendar]') : $('[data-content=calendar]');
		}			
		
		//Append datepicker to focused input parent wrapper // Avoid datepicker floating		
		obj.dpDiv.appendTo(pickerWrapper);
		
	},

	
	beforeShowDay : function(day){
		var me = this;
		
		day.setHours(0,0,0,0);
		var isSelectable = true;

		if (me.calendar) {
			// must be available and checkin
			if (!me.getAvailability(day) || !me.getCheckinStatus(day)) {
				isSelectable = false;
			}
		}

		var classes = 'day-date-' + (+day);
		var tooltip = false;

		return [isSelectable, classes, tooltip];
		
	},
	
	onSelect: function(day, picker){			
		var me = this;

		me.from = new Date(picker.selectedYear, picker.selectedMonth, picker.selectedDay);
		me.fromInput.removeClass('empty');
	},
	
	onClose: function(){
		var me = this;

	},
	
	afterShow: function(stringDate, inst){
		var me = this;
		
		$('.calendar-footer .clear-dates').hide();
	},
	
	/**********************************************************************************************************/
	/****************************************** METHODS *******************************************************/
	/**********************************************************************************************************/
	
	hasDates:  function(){
		var me = this;

		return me.from != null;
	},

	_setDate: function(dayDate, datepicker){
		var me = this;
		
		datepicker('setDate', dayDate);
		
	},
	
	setDate: function(dayDate, picker){
		var me = this;
		if (dayDate == null) return;
		
		dayDate = new Date(dayDate);
		me[picker].datepicker('setDate', dayDate);
	}

});
